import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Table, Empty, Popconfirm, Tag, Space, Avatar, Typography, Skeleton, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper, UsercardWrapper } from '../style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox, TableWrapper } from '../../styled';
import { Button } from '../../../components/buttons/buttons';
import AppContext from '../../../context';
import { Corretor } from 'smart-imob-types';
import { UserModal } from './modal';
import { UserDeleteModal } from '../../../components/users/delete';
import { ChangePasswordModal } from '../../../components/users/change-password';
import { UserCreateModal } from '../../../components/users/create';
import antdTableLocale from '../../../config/locale/antd-table';

const { Text, Paragraph } = Typography;

export const UsersList = () => {
  const context = useContext(AppContext);
  const [viewUser, setViewUser] = useState<Corretor | null>(null);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [openModalRemover, setOpenModalRemover] = useState(false);
  const [openModalAlterarSenha, setOpenModalAlterarSenha] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const maisFiltros: any = [];

        const ordemMapa = {
          recentes: { field: 'cliente.created_at', order: 'DESC' },
          edicao: { field: 'cliente.edited_at', order: 'DESC' },
          meus: { field: 'cliente.corretor_responsavel', order: 'DESC' },
        };

        let params = new URLSearchParams({
          empresa_id: context.empresa.db_id,
          limit: pageSize.toString(),
          startAt: ((current - 1) * pageSize).toString(),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/paginado?${params.toString()}`);

        const data = await response.json();
        setUsers(data.nodes);
        setTotal(data.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageSize, current]);

  const handleDeleteUser = (userId: string) => {
    setSelectedUserId(userId);
    setOpenModalRemover(true);
  };

  const handleChangePassword = (userId: string) => {
    setSelectedUserId(userId);
    setOpenModalAlterarSenha(true);
  };
  const isAdmin = context.user?.permissões.alterar_cadastro || false;

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={record.foto}>{!record.foto && (text ?? '').charAt(0).toUpperCase()}</Avatar>
          <div style={{ marginLeft: 8 }}>
            <Text>{text}</Text>
            <Paragraph type="secondary">{record.telefone}</Paragraph>
          </div>
        </div>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Equipe',
      dataIndex: 'equipe',
      key: 'equipe',
      render: equipe => equipe?.nome,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      render: cargo => {
        let color = cargo === 'Gerente de Locação' ? 'geekblue' : 'green';
        if (cargo === 'Administrador') {
          color = 'volcano';
        }
        if (cargo === 'Corretor') {
          color = 'geekblue';
        }

        if (!cargo) return;
        return (
          <Space>
            <Tag color={color} key={cargo}>
              {(cargo ?? '').toUpperCase()}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, user: any) => {
        return (
          <div className="table-actions">
            <Button className="btn-icon" type="info" shape="circle" onClick={() => setViewUser(user)}>
              <FeatherIcon icon="user" size={16} />
            </Button>
            {(context.user?.permissões?.lead_excluir || context.user?.permissões?.alterar_cadastro) && (
              <>
                {!user.excluido ? (
                  <Popconfirm
                    placement="topRight"
                    title="Tem certeza que quer excluir esse usuario?"
                    onConfirm={() => handleDeleteUser(user.db_id)}
                    okText="Sim, excluir"
                    cancelText="Não"
                  >
                    <Button className="btn-icon" type="danger" shape="circle">
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    placement="topRight"
                    title="Você quer restaurar esse usario?"
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button className="btn-icon" type="info" shape="circle">
                      <FeatherIcon icon="skip-back" size={16} />
                    </Button>
                  </Popconfirm>
                )}
                {isAdmin && (
                  <Button
                    className="btn-icon"
                    type="primary"
                    shape="circle"
                    onClick={() => handleChangePassword(user.db_id)}
                  >
                    <FeatherIcon icon="lock" size={16} />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const maxUsers = context.empresa.assinatura?.qtd_usuarios || 1;
  const canAddUser = (total || 1) < maxUsers;

  const handleClickAddUser = () => {
    if (!canAddUser) {
      message.error(
        `Seu plano comporta até ${maxUsers} usuário${maxUsers === 1 ? '' : 's'}. Entre em contato com o comercial para mais licenças.`,
      );
      return;
    }

    setVisible(true);
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Usuários"
          subTitle={
            loading ? (
              <Skeleton.Input style={{ width: 100 }} active size="small" />
            ) : (
              <span className="title-counter">{`Licenças de usuário em uso: ${total} de ${maxUsers} permitidos`}</span>
            )
          }
          buttons={[
            <Button
              onClick={() => handleClickAddUser()}
              className="btn-add_new"
              size="default"
              type="primary"
              key="1"
            >
              <FeatherIcon icon="plus" size={14} /> Adicionar novo usuário
            </Button>,
          ]}
        />
      </CardToolbox>
      <UserModal usuario={viewUser} visible={!!viewUser} onCancel={() => setViewUser(null)} />
      <UserDeleteModal
        open={openModalRemover}
        setOpen={setOpenModalRemover}
        userId={selectedUserId}
        empresaId={context.empresa?.db_id}
      />
      <UserCreateModal
        visible={visible}
        onCancel={() => setVisible(false)}
        isAdmin={isAdmin}
        empresaId={context.empresa?.db_id}
      />
      <ChangePasswordModal open={openModalAlterarSenha} setOpen={setOpenModalAlterarSenha} userId={selectedUserId} />
      <Main>
        <UsercardWrapper>
          <Row gutter={25}>
            <Col xs={24}>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive" style={{ position: 'relative' }}>
                    <Table
                      loading={loading}
                      locale={{
                        emptyText: (
                          <Empty
                            image={require('../../../static/img/empty.svg')}
                            imageStyle={{
                              height: 60,
                            }}
                          />
                        ),
                      }}
                      dataSource={users}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
                        total: total || 0,
                        current,
                        pageSize,
                        onShowSizeChange: (current, size) => {
                          setPageSize(size);
                        },
                        onChange: page => {
                          setCurrent(page);
                        },
                        ...antdTableLocale,
                      }}
                    />
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Col>
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
};
