import React, { lazy, Suspense, useContext, useState, useEffect } from 'react';
import { Row, Col, Skeleton, Spin, Popconfirm, message } from 'antd';
import { NavLink, Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import styled from 'styled-components';
import moment from 'moment';
import { SettingWrapper } from './overview/style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import AppContext from '../../../context';
import SmartCache from '../../../indexeddb';
import ClienteCards from '../../pages/overview/ClienteCard';
import { Button } from '../../../components/buttons/buttons';
import RegistrarPropostaModal from '../../lista-de-imoveis/overview/RegistrarPropostaModal';
import RegistrarVendaModal from '../../lista-de-imoveis/overview/RegistrarVendaModal';
import RegistrarVisitaModal from '../../lista-de-imoveis/overview/RegistrarVisitaModal';
import RegistrarAlugelModal from '../../lista-de-imoveis/overview/RegistrarAluguelModal';
import { database } from '../../../firebase';
import { UserCards } from '../../../components/users';

const CoverSection = lazy(() => import('../overview/ImovelCoverSection'));
const ImovelBio = lazy(() => import('./overview/ImovelBio'));
const Analytics = lazy(() => import('./overview/Analytics'));
const Fotos = lazy(() => import('./overview/Fotos'));
const Excluidos = lazy(() => import('./overview/Excluded'));
const Perfil = lazy(() => import('./overview/Overview'));
const Interessados = lazy(() => import('./overview/Interessados'));
const Historico = lazy(() => import('./overview/Historico'));
const ContatoProprietario = lazy(() => import('../cliente/overview/ContatoProprietario'));
const VisitasEPropostas = lazy(() => import('./overview/VisitasEPropostas'));
const Ficha = lazy(() => import('./overview/Ficha'));
const Erro = lazy(() => import('../../pages/404'));

function checarDatas(datebank) {
  if (datebank != null) {
    const today = new Date();

    if (today < datebank) {
      return true;
    }
    return false;
  }
  return false;
}

const ImovelPage = props => {
  console.log('modoCliente', props);
  const context = useContext(AppContext);
  const { url, params } = useRouteMatch();
  const imovel_dbid = params.id_imovel != undefined ? params.id_imovel : props.imovel_dbid;
  const [navLink, setNavLink] = useState(props.imovelModalAba || 'visao_geral');

  const [Imovel, setImovel] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [Proprietario, setProprietario] = useState(null);

  const [Visita, setVisita] = useState(false);
  const [Venda, setVenda] = useState(false);
  const [Locacao, setLocacao] = useState(false);
  const [Proposta, setProposta] = useState(false);

  const cancelarVisita = () => setVisita(false);
  const cancelarVenda = () => setVenda(false);
  const cancelarLocacao = () => setLocacao(false);
  const cancelarProposta = () => setProposta(false);

  const campos_personalizados_relate_categ = (context.empresa.campos_personalizados || []).reduce((prev, curr) => {
    prev[curr.nome] = curr.categ || 0;
    return prev;
  }, {});

  const campos_personalizados_values = (Imovel
    ? (Imovel.campos_personalizados_values || []).filter(campo => campo.value && campo.nome)
    : []
  ).sort((a, b) => a.nome.localeCompare(b.nome));

  useEffect(() => {
    (async () => {
      setNavLink(props.imovelModalAba || 'visao_geral');
    })();
  }, [props]);

  useEffect(() => {
    const old_title = new String(document.title);
    (async () => {
      setLoading(true);
      try {
        if (imovel_dbid) {
          // const imovel_found = await SmartCache.imoveis.getById(imovel_dbid);
          const imovelRequest = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${imovel_dbid}`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
          const imovelData = await imovelRequest.json();
          setImovel(imovelData);
          document.title = `SmartImob - Imóvel #${imovelData.codigo || imovelData.titulo || imovelData.db_id}`;
          setProprietario(imovelData?.proprietario);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();

    return () => (document.title = old_title);
  }, [imovel_dbid]);

  if (Loading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );

  if (
    !Imovel ||
    (Imovel &&
      context.user.permissões.imovel_ver_todos === false &&
      context.user.permissões.alterar_cadastro === false &&
      Imovel.agenciador_id !== context.user.db_id)
  )
    return (
      <Erro
        title="Nenhum imóvel foi encontrado com esse ID."
        btnText="Voltar para a listagem de imóveis"
        btnLink="/admin/lista-de-imoveis"
      />
    );

  const ag = context.users_empresa.find(user => user.db_id === Imovel.agenciador_id);
  const agExterno = Imovel.agenciador_externo_nome ? Imovel.agenciador_externo_nome : null;

  const update_excluido = async (excluido = true) => {
    try {
      if (!context.user.permissões.imovel_excluir && !context.user.permissões.alterar_cadastro) {
        return message.error('Você não tem as permissões para essa ação.');
      }
      await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${Imovel.db_id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(data => data.json())
        .then(data => console.log('Excluir Imóvel: ', data));
      message.success(`Imóvel ${excluido ? 'excluído' : 'restaurado'} com sucesso!`);
    } catch (error) {
      message.error(`Erro ao ${excluido ? 'excluir' : 'restaurar'} esse imóvel!`);
      console.error(error);
    }
  };

  const excluir = () => update_excluido(true);
  const restaurar = () => update_excluido(false);

  const showRevisadoPermissao =
    context.user?.permissões.alterar_cadastro === true || Imovel?.agenciador_id === context.user?.db_id;

  const showRevisado = 'Revisado' in campos_personalizados_relate_categ;

  const revisadoValue = campos_personalizados_values.find(campo => campo.nome === 'Revisado')?.value;
  const revisadoToggle = async () => {
    if (Imovel) {
      try {
        // jesus cristo, deve ter algum jeito mais facil de fazer isso
        const campos_empresa_mais_imoveis = context.empresa.campos_personalizados.map(campo => ({
          ...campo,
          ...(Imovel.campos_personalizados_values || []).find(campo_imovel => campo_imovel.nome === campo.nome),
        }));
        const revisadoImovelCampo = (Imovel.campos_personalizados_values || []).find(
          campo => campo.nome === 'Revisado',
        );
        for (const campo of campos_empresa_mais_imoveis) {
          if (!campo) continue;
          delete campo.created_at;
          delete campo.escolhas;
          if (campo.nome === 'Revisado') {
            campo.value = !revisadoImovelCampo?.value;
            break;
          }
        }
        const toUpdate = {
          campos_personalizados_values: campos_empresa_mais_imoveis.filter(campo => campo.value),
        };
        setImovel(imovel_state => ({ ...imovel_state, ...toUpdate }));
        await Promise.all([
          SmartCache.imoveis.updateById(Imovel.db_id, toUpdate),
          database.doc(`empresas/${context.user?.empresa}/imoveis/${Imovel.db_id}`).update(toUpdate),
        ]);
        message.success('Status de revisão alterado!');
      } catch (error) {
        console.log(error);
        message.error('Algo deu errado ao alterar o status de revisão!');
      }
    }
  };

  const onChangeComponent = id => {
    setNavLink(id);
  };

  return (
    <>
      <RegistrarVendaModal db_id={Imovel.db_id} visible={Venda} onCancel={cancelarVenda} />
      <RegistrarAlugelModal db_id={Imovel.db_id} visible={Locacao} onCancel={cancelarLocacao} />
      <RegistrarPropostaModal db_id={Imovel.db_id} visible={Proposta} onCancel={cancelarProposta} />
      <RegistrarVisitaModal db_id={Imovel.db_id} visible={Visita} onCancel={cancelarVisita} />
      <PageHeader
        ghost
        back
        title={Imovel.titulo || `Imóvel #${Imovel.codigo}`}
        modalImovel={props.modalImovel}
        buttons={
          !props.modoCliente && (
            <PageButtons>
              {!Imovel.excluído &&
                (context.user.permissões.imovel_excluir === true ||
                  context.user.permissões.alterar_cadastro === true) && (
                  <span key="5" className="page-header-actions">
                    <Popconfirm
                      onConfirm={excluir}
                      okText="Sim"
                      cancelText="Não, cancelar"
                      title="Tem certeza que quer excluir esse imóvel?"
                    >
                      <Button size="small" transparented type="danger">
                        <ForcarSvg>
                          <FeatherIcon icon="trash" size={14} />
                        </ForcarSvg>
                        Excluir imóvel
                      </Button>
                    </Popconfirm>
                  </span>
                )}

              {Imovel.excluído &&
                (context.user.permissões.imovel_excluir === true ||
                  context.user.permissões.alterar_cadastro === true) && (
                  <span key="5" className="page-header-actions">
                    <Popconfirm
                      onConfirm={restaurar}
                      okText="Sim"
                      cancelText="Não, cancelar"
                      title="Tem certeza que quer restaurar esse imóvel?"
                    >
                      <Button size="small" transparented type="secondary">
                        <ForcarSvg>
                          <FeatherIcon icon="chevrons-up" size={14} />
                        </ForcarSvg>
                        Restaurar imóvel
                      </Button>
                    </Popconfirm>
                  </span>
                )}
              {
                <span key="4" className="page-header-actions">
                  <Button onClick={() => setVisita(true)} size="small" key="4" type="primary">
                    <ForcarSvg>
                      <FeatherIcon icon="home" size={14} />
                    </ForcarSvg>
                    Registrar visita
                  </Button>
                </span>
              }
              {
                <span key="3" className="page-header-actions">
                  <Button onClick={() => setVenda(true)} size="small" key="3" type="primary">
                    <ForcarSvg>
                      <FeatherIcon icon="dollar-sign" size={14} />
                    </ForcarSvg>
                    Registrar venda
                  </Button>
                </span>
              }
              {
                <span key="6" className="page-header-actions">
                  <Button onClick={() => setLocacao(true)} size="small" key="6" type="primary">
                    <ForcarSvg>
                      <FeatherIcon icon="dollar-sign" size={14} />
                    </ForcarSvg>
                    Registrar Locação
                  </Button>
                </span>
              }
              {
                <span key="2" className="page-header-actions">
                  <Button
                    onClick={() =>
                      checarDatas(Imovel.propostas?.o_?.__[0]?.validade)
                        ? message.warning(
                            `Esse imovel já tem uma proposta ativa até ${moment(
                              Imovel.propostas?.o_?.__[0]?.validade,
                            ).format('DD/MM/YYYY')}`,
                          )
                        : setProposta(true)
                    }
                    size="small"
                    key="4"
                    type="primary"
                  >
                    <ForcarSvg>
                      <FeatherIcon icon="dollar-sign" size={14} />
                    </ForcarSvg>
                    Registrar proposta
                  </Button>
                </span>
              }
              {(context.user.permissões.imovel_editar === true ||
                Imovel.agenciador_id === context.user.db_id ||
                context.user.permissões.alterar_cadastro === true) && (
                <Link key="1" className="page-header-actions" to={`/admin/editar-imovel/${imovel_dbid}`}>
                  <Button size="small" key="4" type="primary">
                    <ForcarSvg>
                      <FeatherIcon icon="edit-2" size={14} />
                    </ForcarSvg>
                    Editar imóvel
                  </Button>
                </Link>
              )}
              {showRevisadoPermissao && showRevisado && (
                <span key="5" className="page-header-actions">
                  <Button onClick={revisadoToggle} size="small" key="4" type="primary">
                    Revisado: {revisadoValue ? 'Sim' : 'Não'}
                  </Button>
                </span>
              )}
            </PageButtons>
          )
        }
      />
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              {context.user.permissões.visualizar_proprietario &&
              Imovel.agenciador_id === context.user.db_id &&
              Proprietario &&
              !props.modoCliente ? (
                <ClienteCards showContato showPerfil title="Proprietário" user={Proprietario} />
              ) : null}
              {!context.user.permissões.visualizar_proprietario && Proprietario && !props.modoCliente ? (
                <ClienteCards showContato showPerfil title="Proprietário" user={Proprietario} />
              ) : null}
            </Suspense>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              <ImovelBio modoCliente={props.modoCliente} imovel={Imovel} />
            </Suspense>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar active paragraph={{ rows: 3 }} />
                </Cards>
              }
            >
              {ag && <UserCards user={ag} title="Agenciador" />}
              {agExterno && <UserCards user={agExterno} title="Agenciador Externo" />}
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <div className="coverWrapper">
                  <CoverSection foto={Imovel.fotos && Imovel.fotos[Imovel.foto_destaque_index]} />
                  <nav className="profileTab-menu">
                    <ul>
                      <li>
                        <a
                          className={navLink === 'visao_geral' ? 'active' : ''}
                          onClick={() => onChangeComponent('visao_geral')}
                        >
                          Visão geral
                        </a>
                      </li>
                      <li>
                        <a className={navLink === 'fotos' ? 'active' : ''} onClick={() => onChangeComponent('fotos')}>
                          Fotos & Anexos
                        </a>
                      </li>
                      {!props.modoCliente && (
                        <>
                          <li>
                            <a
                              className={navLink === 'analytics' ? 'active' : ''}
                              to={`${url}`}
                              onClick={() => onChangeComponent('analytics')}
                            >
                              Analytics
                            </a>
                          </li>
                          <li>
                            <a
                              className={navLink === 'interessados' ? 'active' : ''}
                              onClick={() => onChangeComponent('interessados')}
                            >
                              Interessados
                            </a>
                          </li>
                          <li>
                            <a
                              className={navLink === 'visitas-e-propostas' ? 'active' : ''}
                              onClick={() => onChangeComponent('visitas-e-propostas')}
                            >
                              Reservas & Propostas
                            </a>
                          </li>
                          <li>
                            <a
                              className={navLink === 'contato_proprietario' ? 'active' : ''}
                              onClick={() => onChangeComponent('contato_proprietario')}
                            >
                              Contato Proprietário
                            </a>
                          </li>
                          <li>
                            <a
                              className={navLink === 'historico' ? 'active' : ''}
                              onClick={() => onChangeComponent('historico')}
                            >
                              Histórico
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>
              </Suspense>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active paragraph={{ rows: 10 }} />
                    </Cards>
                  }
                >
                  {navLink === 'visao_geral' && (
                    <Ficha
                      campos_personalizados_relate_categ={campos_personalizados_relate_categ}
                      campos_personalizados_values={campos_personalizados_values}
                      db_id={Imovel.db_id}
                      fotos={Imovel.fotos}
                      imovel_codigo={Imovel.codigo}
                    />
                  )}
                  {navLink === 'fotos' && (
                    <Fotos
                      ag_id={Imovel.agenciador_id}
                      db_id={Imovel.db_id}
                      imovel_codigo={Imovel.codigo}
                      anexos={Imovel.anexos}
                      fotos={Imovel.fotos}
                    />
                  )}
                  {navLink === 'analytics' && <Analytics imovel={Imovel} />}
                  {navLink === 'interessados' && <Interessados imovel={Imovel} />}
                  {navLink === 'visitas-e-propostas' && <VisitasEPropostas imovel={Imovel} />}
                  {navLink === 'historico' && <Historico db_id={Imovel.db_id} />}
                  {navLink === 'contato_proprietario' && (
                    <ContatoProprietario empresa={context.empresa} db_id={Imovel.db_id} imovel={Imovel} />
                  )}
                </Suspense>
              </Switch>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
};

const PageButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .page-header-actions {
    margin: unset;
  }
`;

const ForcarSvg = styled.span`
  & {
    display: flex !important;
    margin-right: 6px;
  }
  & > svg {
    width: 14px !important;
    height: 14px !important;
  }
`;

export default ImovelPage;
