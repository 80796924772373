import React, { useState, useEffect, useCallback } from 'react';
import { AutoComplete, Spin, Form } from 'antd';
import { AutoCompleteWrapper } from '../../container/styled';
import { Imóvel } from 'smart-imob-types';

interface FiltrarImoveisProps {
  handleChangeImovel: any;
  empresa_id: string;
  cleanStateOnSelect?: boolean;
  imovel_obj?: any;
  placeholder?: string;
  style?: any;
  limit?: string;
  startAt?: string;
  ordem?: string;
}

const FiltrarImoveis = ({
  handleChangeImovel,
  cleanStateOnSelect = false,
  imovel_obj,
  empresa_id,
  placeholder,
  limit = '75',
  startAt = '0',
  ordem = 'alfabetica',
  style,
}: FiltrarImoveisProps) => {
  const [imoveis, setImoveis] = useState<Imóvel[]>([]);
  const [imoveisSearch, setImoveisSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchImoveis = async () => {
    setLoading(true);
    try {
      const filtros = JSON.stringify([
        {
          field: 'imovel.codigo',
          value: `%${imoveisSearch}%`,
          operator: 'like',
        },
      ]);
      const params = new URLSearchParams({
        empresa_id,
        limit,
        startAt,
        order: JSON.stringify([
          ordem === 'alfabetica'
            ? { field: 'imovel.codigo', order: 'ASC' }
            : { field: 'imovel.created_at', order: 'DESC' },
        ]),
        filtros,
      });
      const url = `${process.env.REACT_APP_API_URI}/imoveis/paginado?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      setImoveis(result.nodes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImoveis();
  }, []);

  useEffect(() => {
    fetchImoveis();
  }, [imoveisSearch]);

  const onSearch = text => {
    setImoveisSearch(text);
  };

  const onSelect = (db_id, obj) => {
    handleChangeImovel(obj);
    if (cleanStateOnSelect) {
      setImoveisSearch('');
    }
  };

  const onChange = value => {
    handleChangeImovel(null);
  };

  const options = imoveis.map(imovel => ({ value: imovel.db_id, label: imovel.codigo }));

  return (
    <AutoCompleteWrapper style={{ width: '100%', marginBottom: 10, ...style }}>
      <AutoComplete
        size="large"
        onChange={onChange}
        value={imovel_obj?.label}
        onSelect={onSelect}
        style={{ width: '100%' }}
        placeholder={placeholder || 'Imóvel'}
        options={options}
        onSearch={onSearch}
      />
    </AutoCompleteWrapper>
  );
};

export default FiltrarImoveis;
