import React from 'react';
import PropTypes from 'prop-types';
import { PageHeaderStyle } from './style';
import { Button } from '../buttons/buttons';
import FeatherIcons from 'feather-icons-react';
import { useHistory } from 'react-router-dom';

const PageHeader = props => {
  const { title, subTitle, routes, buttons, ghost, bgColor, back = false, multiBtn = false, modalImovel } = props;
  let history = useHistory();
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor || '#F4F5F7',
          marginBottom: back ? -6 : 0,
          marginTop: back ? -6 : 0,
          marginLeft: back ? -6 : 0,
        }}
      >
        <PageHeaderStyle
          back={back}
          style={{
            backgroundColor: 'rgb(244, 245, 247)',
          }}
          //onBack={() => window.history.back()}
          title={
            back === true && modalImovel != true ? (
              <>
                <Button
                  onClick={history.goBack}
                  transparented
                  type="primary"
                  style={{ marginRight: 20, marginTop: 6, marginBottom: 6, marginLeft: 6 }}
                >
                  <FeatherIcons size={12} icon="arrow-left" />
                  Voltar
                </Button>
                {title}
              </>
            ) : (
              title
            )
          }
          subTitle={subTitle}
          breadcrumb={routes && { routes }}
          extra={buttons}
          ghost={ghost}
        />
      </div>
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgColor: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  ghost: PropTypes.bool,
  back: PropTypes.bool,
};

export { PageHeader };
