import React, { useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import AppContext from '../context';
import SmartCache from '../indexeddb';
import firebase from '../firebase';
import { Key } from 'react-feather';

const MenuItems = React.memo(
  ({ darkMode, toggleCollapsed, toggleCollapsedFunction, topMenu }) => {
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
    const context = useContext(AppContext);

    async function SignOut() {
      try {
        await Promise.all([
          SmartCache.imoveis.deleteAll(),
          SmartCache.clientes.deleteAll(),
          SmartCache.fotos_upload.deleteAll(),
          SmartCache.empreendimentos.deleteAll(),
          SmartCache.visitas.deleteAll(),
        ]);
        firebase.auth().signOut();
      } catch (error) {
        console.log(error.message);
      }
    }

    useEffect(() => {
      if (context.info_empresa.pagamento_atrasado) {
        SignOut();
      }
    }, [context.info_empresa]);
    const { user } = context;
    const { permissões } = user;

    return (
      <Menu
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        theme={darkMode && 'dark'}
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                }`,
              ]
            : []
        }
        selectedKeys={[mainPath || 'home']}
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      >
        <Menu.Item
          key="home"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}`}>
                <FeatherIcon icon="globe" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            Dash de Performance
          </NavLink>
        </Menu.Item>
        {(permissões.financeiro || permissões.alterar_cadastro) && (
          <Menu.Item
            key="/dash-vendas"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-vendas`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-vendas`}>
              Dash de Vendas
            </NavLink>
          </Menu.Item>
        )}
        {/* {(permissões.financeiro || permissões.alterar_cadastro) && (
          <Menu.Item
            key="/dash-locacao"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-locacao`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-locacao`}>
              Dash de Locação
            </NavLink>
          </Menu.Item>
        )} */}
        <Menu.Item
          key="/dash-corretor"
          icon={
            !topMenu && (
              <NavLink to={`${path}/dash-corretor`}>
                <FeatherIcon icon="git-pull-request" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/dash-corretor`}>
            Dash do Corretor
          </NavLink>
        </Menu.Item>
        {permissões.alterar_cadastro && context.empresa.utilizar_temporada && (
          <Menu.Item
            key="/dash-temporada"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-temporada`}>
                  <FeatherIcon icon="map" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-temporada`}>
              Dash de Temporada
            </NavLink>
          </Menu.Item>
        )}
        <p className="sidebar-nav-title">
          IMÓVEIS {permissões.empreendimentos || permissões.alterar_cadastro ? '& EMPREENDIMENTOS' : ''}
        </p>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-imoveis`}>
                <FeatherIcon icon="home" />
              </NavLink>
            )
          }
          key="/lista-de-imoveis"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-imoveis`}>
            Lista de imóveis
          </NavLink>
        </Menu.Item>
        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          (permissões.imovel_criar || permissões.alterar_cadastro) && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/incluir-imovel`}>
                    <FeatherIcon icon="plus" />
                  </NavLink>
                )
              }
              key="/incluir-imovel"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/incluir-imovel`}>
                Incluir imóvel
              </NavLink>
            </Menu.Item>
          )}
        {(permissões.empreendimentos || permissões.alterar_cadastro) && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/empreendimentos`}>
                  <FeatherIcon icon="box" />
                </NavLink>
              )
            }
            key="/empreendimentos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/empreendimentos`}>
              Empreendimentos
            </NavLink>
          </Menu.Item>
        )}
        {(permissões.empreendimentos || permissões.alterar_cadastro) && context.empresa.utilizar_construtoras && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/construtoras`}>
                  <FeatherIcon icon="truck" />
                </NavLink>
              )
            }
            key="/construtoras"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/construtoras`}>
              Construtoras
            </NavLink>
          </Menu.Item>
        )}
        {((permissões.alterar_cadastro && context.empresa.utilizar_temporada) ||
          context.user.categoria === 'locacao') && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/reservas`}>
                  <FeatherIcon icon="map-pin" />
                </NavLink>
              )
            }
            key="/reservas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/reservas`}>
              Reservas
            </NavLink>
          </Menu.Item>
        )}
        {(permissões.imovel_editar || permissões.alterar_cadastro) && context.empresa.utilizar_arquitetos && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/arquitetos`}>
                  <FeatherIcon icon="users" />
                </NavLink>
              )
            }
            key="/arquitetos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/arquitetos`}>
              Arquitetos
            </NavLink>
          </Menu.Item>
        )}
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/visitas`}>
                  <Key size={16} />
                </NavLink>
              )
            }
            key="/visitas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/visitas`}>
              Visitas
            </NavLink>
          </Menu.Item>
        }
        {(permissões.alterar_cadastro || context.user.categoria === 'venda') && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/propostas`}>
                  <FeatherIcon icon="clipboard" />
                </NavLink>
              )
            }
            key="/propostas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/propostas`}>
              Propostas
            </NavLink>
          </Menu.Item>
        )}
        <p className="sidebar-nav-title">CLIENTES</p>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-clientes`}>
                <FeatherIcon icon="user-check" />
              </NavLink>
            )
          }
          key="/lista-de-clientes"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-clientes`}>
            Lista de clientes
          </NavLink>
        </Menu.Item>
        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          (permissões.lead_criar || permissões.alterar_cadastro) && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/incluir-cliente`}>
                    <FeatherIcon icon="user-plus" />
                  </NavLink>
                )
              }
              key="/incluir-clientes"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/incluir-cliente`}>
                Incluir cliente
              </NavLink>
            </Menu.Item>
          )}
        {((!context.empresa.pipelines_alternativas && permissões.pipeline_leads && context.user?.acesso_venda) ||
          permissões.alterar_cadastro) && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes`}>
                  <FeatherIcon icon="activity" />
                </NavLink>
              )
            }
            key="/pipeline-de-clientes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes`}>
              Funil de Vendas
            </NavLink>
          </Menu.Item>
        )}
        {/* {((permissões.pipeline_leads && permissões.alterar_cadastro) || context.user.categoria === "locacao") && ( */}
        {/* {!context.empresa.pipelines_alternativas && (permissões.pipeline_leads || permissões.alterar_cadastro) && context.empresa.utilizar_locacao && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes-locacao`}>
                  <FeatherIcon icon="activity" />
                </NavLink>
              )
            }
            key="/pipeline-de-clientes-locacao"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes-locacao`}>
              Pipeline de Locação
            </NavLink>
          </Menu.Item>
        )} */}
        {/* {permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/roleta-de-leads`}>
                  <FeatherIcon icon="refresh-ccw" />
                </NavLink>
              )
            }
            key="/roleta-de-leads"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/roleta-de-leads`}>
              Roleta de clientes
            </NavLink>
          </Menu.Item>
        )} */}
        {/* {context.empresa.pipelines_alternativas && (
          <>
            <p className="sidebar-nav-title">PIPELINES - LOCAÇÃO</p>
            {(permissões.pipeline_leads || permissões.alterar_cadastro) && (
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-locacao-pre-atendimento`}>
                      <FeatherIcon icon="activity" />
                    </NavLink>
                  )
                }
                key="/pipeline-locacao-pre-atendimento"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-locacao-pre-atendimento`}>
                  Pré-Atendimento
                </NavLink>
              </Menu.Item>
            )}
            {(permissões.pipeline_leads || permissões.alterar_cadastro) && (
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-locacao-comercial`}>
                      <FeatherIcon icon="dollar-sign" />
                    </NavLink>
                  )
                }
                key="/pipeline-locacao-comercial"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-locacao-comercial`}>
                  Comercial
                </NavLink>
              </Menu.Item>
            )}
          </>
        )} */}
        {/* {context.empresa.pipelines_alternativas && (
          <>
            <p className="sidebar-nav-title">PIPELINES - VENDA</p>
            {(permissões.pipeline_leads || permissões.alterar_cadastro) && (
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-venda-pre-atendimento`}>
                      <FeatherIcon icon="activity" />
                    </NavLink>
                  )
                }
                key="/pipeline-venda-pre-atendimento"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-venda-pre-atendimento`}>
                  Pré-Atendimento
                </NavLink>
              </Menu.Item>
            )}
            {(permissões.pipeline_leads || permissões.alterar_cadastro) && (
              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-venda-comercial`}>
                      <FeatherIcon icon="dollar-sign" />
                    </NavLink>
                  )
                }
                key="/pipeline-venda-comercial"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-venda-comercial`}>
                  Comercial
                </NavLink>
              </Menu.Item>
            )}
          </>
        )} */}
        <p className="sidebar-nav-title">EQUIPE & EMPRESA</p>
        {permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/configuracoes`}>
                  <FeatherIcon icon="settings" />
                </NavLink>
              )
            }
            key="/configuracoes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/configuracoes`}>
              Configurações da Empresa
            </NavLink>
          </Menu.Item>
        )}

        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          permissões.alterar_cadastro && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/relatorios`}>
                    <FeatherIcon icon="pie-chart" />
                  </NavLink>
                )
              }
              key="/relatorios"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/relatorios`}>
                Relatórios
              </NavLink>
            </Menu.Item>
          )}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/equipe`}>
                <FeatherIcon icon="users" />
              </NavLink>
            )
          }
          key="/equipe"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/equipe`}>
            Usuários
          </NavLink>
        </Menu.Item>
        {permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/equipes`}>
                  <FeatherIcon icon="target" />
                </NavLink>
              )
            }
            key="/equipes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/equipes`}>
              Distribuição de Leads
            </NavLink>
          </Menu.Item>
        )}
        {permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/unidades`}>
                  <FeatherIcon icon="pocket" />
                </NavLink>
              )
            }
            key="/unidades"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/unidades`}>
              Unidades
            </NavLink>
          </Menu.Item>
        )}
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/tarefas`}>
                  <FeatherIcon icon="layout" />
                </NavLink>
              )
            }
            key="/tarefas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/tarefas`}>
              Tarefas
            </NavLink>
          </Menu.Item>
        }
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/agenda`}>
                  <FeatherIcon icon="calendar" />
                </NavLink>
              )
            }
            key="/agenda"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/agenda`}>
              Agenda
            </NavLink>
          </Menu.Item>
        }
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/central_anexos`}>
                  <FeatherIcon icon="folder" />
                </NavLink>
              )
            }
            key="/central_anexos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/central_anexos`}>
              Central de Anexos
            </NavLink>
          </Menu.Item>
        }
        {(permissões.marketing || permissões.alterar_cadastro) && (
          <>
            <p className="sidebar-nav-title">MARKETING</p>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/campanhas`}>
                    <FeatherIcon icon="award" />
                  </NavLink>
                )
              }
              key="/campanhas"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/campanhas`}>
                Campanhas Meta
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item
              icon={
                !topMenu && (
                  <a target="_blank" href={`https://smtximob.com/builder?empresa=${context.user.empresa}`}>
                    <FeatherIcon icon="send" />
                  </a>
                )
              }
            >
              <a target="_blank" href={`https://smtximob.com/builder?empresa=${context.user.empresa}`}>
                Landing Pages
              </a>
            </Menu.Item> */}
            {/* <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/email-marketing`}>
                    <FeatherIcon icon="mail" />
                  </NavLink>
                )
              }
              key="/email-marketing"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/email-marketing`}>
                Email Marketing
              </NavLink>
            </Menu.Item> */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/portais`}>
                    <FeatherIcon icon="share" />
                  </NavLink>
                )
              }
              key="/portais"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/portais`}>
                Portais
              </NavLink>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                    <FeatherIcon icon="book" />
                  </NavLink>
                )
              }
              key="/blogs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                Blogs
              </NavLink>
            </Menu.Item>
            {
              // (permissões.alterar_cadastro) && <Menu.Item key="/dash-redes" icon={!topMenu && <NavLink style={{pointerEvents: 'none', opacity: 0.5}} onClick={toggleCollapsed} to={`${path}/dash-redes`}><FeatherIcon icon="share-2"/></NavLink>}>
              //  <NavLink style={{pointerEvents: 'none', opacity: 0.5}} onClick={toggleCollapsed} to={`${path}/dash-redes`}>
              //    Dash Social (Em breve)
              //  </NavLink>
              // </Menu.Item>
            }
          </>
        )}
        <p className="sidebar-nav-title">AJUDA</p>
        <Menu.Item
          key="/tutoriais"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/tutoriais`}>
                <FeatherIcon icon="youtube" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/tutoriais`}>
            Tutoriais
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/suporte`}>
                <FeatherIcon icon="help-circle" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/suporte`}>
            Suporte
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="/novidades"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/novidades`}>
                <FeatherIcon icon="git-commit" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/novidades`}>
            Novidades
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to="#">
                <FeatherIcon icon="skip-forward" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsedFunction} to="#">
            Tour guiado
          </NavLink>
        </Menu.Item>
        <div style={{ height: 10 }} />
      </Menu>
    );
  },
  (prevProps, nextProps) => {
    // dont update
    return true;
  },
);

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
