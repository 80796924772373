import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Radio, Checkbox, Select, message } from 'antd';
const { Option } = Select;
import { BasicFormWrapper } from '../../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarAgenciador from '../../../components/filtros-smart/usuarios';
import 'moment/locale/pt-br';
import FiltrarImoveis from '../../../components/filtros-smart/imoveis';
import AppContext from '../../../context';
import { Cliente, Imóvel } from 'smart-imob-types';

interface ModalAddClientesProps {
  visible: boolean;
  onCancel: () => void;
  cliente: Cliente | null;
  imovel_origem_cod: string;
  onFinishEdit?: (updatedCliente: any) => void;
  imovel_padrao?: Imóvel;
}
export const ModalEditClientes = ({
  visible,
  onCancel,
  cliente: update,
  imovel_origem_cod,
  onFinishEdit,
  imovel_padrao,
}: ModalAddClientesProps) => {
  const [form] = Form.useForm();

  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [tipos, setTipos] = useState<{ id: string; nome: string }[]>([]);
  const [editAg, editChangeAg] = useState<any | null>(null);
  const [editMQL, editChangeMQL] = useState<any | null>(null);
  const [ImovelObj, setImovelObj] = useState<null | any>(imovel_padrao);

  const corretoresRelate: Record<string, string> = context.users_empresa.reduce((prev: any, curr: any) => {
    prev[curr.db_id] = curr.nome || curr.email || curr.db_id;
    return prev;
  }, {} as Record<string, string>);

  useEffect(() => {
    if (context.user?.db_id) {
      editChangeAg({
        value: context.user?.db_id,
        label: context.user?.nome || context.user?.db_id,
      });
    }

    const fetchData = async () => {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const url = `${process.env.REACT_APP_API_URI}/tipos?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const tipos = await response.json();
      setTipos(tipos);
    };
    fetchData();
  }, []);

  const onTagChange = async tags => {
    if (tags && tags.length > 0) {
      await fetch(`${process.env.REACT_APP_API_URI}/empresas/${context.empresa.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          etiquetas: tags,
        }),
      });
    }
  };

  const funil_etapa_venda_nomes = context.empresa ? (context.empresa as any).funil_etapa_venda_nomes || {} : {};

  const handleOk = async (values: any) => {
    if (context.user?.permissões.lead_editar === false && context.user?.permissões.alterar_cadastro === false) {
      return message.error('Seu usuário não tem permissão para editar clientes.');
    }
    setLoading(true);
    try {
      // Remove campos indefinidos
      Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);

      let cliente_to_update = {
        ...values,
        edited_at: new Date(),
      };

      const selectedEtapa = context.empresa?.funil_etapas_extra?.find(etapa => etapa.id === values.funil_etapa_venda);
      if (selectedEtapa?.remover_corretor_responsavel) {
        cliente_to_update['corretor_responsavel'] = null;
      } else if (editAg && editAg.value) {
        cliente_to_update['corretor_responsavel'] = editAg.value;
      }

      if (editMQL && editMQL.value) {
        cliente_to_update['MQL'] = editMQL.value;
      }

      if (ImovelObj && ImovelObj.value) {
        cliente_to_update['imovel_origem'] = ImovelObj.value;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${update?.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cliente_to_update),
      });

      if (!response.ok) {
        throw new Error('Falha ao atualizar o cliente');
      }

      const updatedCliente = await response.json();

      onCancel();
      form.resetFields();
      if (onFinishEdit) {
        onFinishEdit(updatedCliente);
      }
      message.success('Cliente atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar cliente:', error);
      message.error('Erro ao atualizar cliente.');
    } finally {
      setLoading(false);
    }
  };

  if (!update) {
    return <></>;
  }

  return (
    <Modal type={'primary'} title="Informações do cliente" visible={visible} footer={null} onCancel={onCancel}>
      <div className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <Form form={form} name="contactEdit" onFinish={handleOk}>
              <Form.Item required={true} initialValue={update.nome} label="Nome" name="nome">
                <Input required={true} placeholder="Nome" />
              </Form.Item>

              <Form.Item label="Email" name="email" initialValue={update.email}>
                <Input placeholder="email@examplo.com" />
              </Form.Item>

              <label className="form-item">Telefone</label>
              <div style={{ display: 'flex' }}>
                <Form.Item name="DDD" required={true} initialValue={update.DDD} style={{ width: 80, marginRight: 6 }}>
                  <Input required={true} placeholder="DDD" />
                </Form.Item>
                <Form.Item name="telefone" required={true} initialValue={update.telefone} style={{ width: '100%' }}>
                  <Input required={true} placeholder="Telefone" />
                </Form.Item>
              </div>

              <Form.Item name="CPF" initialValue={update.CPF} label="CPF">
                <Input placeholder="Número do CPF" />
              </Form.Item>

              <Form.Item initialValue={update.conjuge_nome || ''} name="conjuge_nome" label="Nome do cônjuge">
                <Input placeholder="Nome completo do cônjuge" />
              </Form.Item>
              <Form.Item
                initialValue={update.conjuge_telefone || ''}
                name="conjuge_telefone"
                label="Telefone do cônjuge"
              >
                <Input placeholder="Telefone do cônjuge" />
              </Form.Item>

              <Form.Item initialValue={update.etiquetas || []} name="etiquetas" label="Etiquetas">
                {context.user?.permissões?.alterar_cadastro ? (
                  <Select mode="tags" onChange={onTagChange} style={{ width: '100%' }} placeholder="Etiquetas">
                    {// @ts-ignore
                    (context.empresa.etiquetas || []).map(etiqueta => (
                      <Option key={etiqueta} value={etiqueta}>
                        {etiqueta}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select mode="multiple" style={{ width: '100%' }} placeholder="Etiquetas">
                    {// @ts-ignore
                    (context.empresa.etiquetas || []).map(etiqueta => (
                      <Option key={etiqueta} value={etiqueta}>
                        {etiqueta}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 8 }}
                initialValue={!!update.proprietario}
                valuePropName={'checked'}
                name="proprietario"
                label="Este cliente é um proprietário?"
              >
                <Checkbox>Sim, este cliente é um proprietário</Checkbox>
              </Form.Item>

              <Form.Item>
                {visible && (
                  <FiltrarImoveis
                    cleanStateOnSelect={true}
                    placeholder={
                      update.imovel_origem
                        ? imovel_origem_cod
                          ? `Imóvel de origem: #${imovel_origem_cod}`
                          : 'Imóvel de origem'
                        : 'Imóvel de origem'
                    }
                    style={{ height: '100%' }}
                    imovel_obj={ImovelObj}
                    handleChangeImovel={setImovelObj}
                    empresa_id={context.empresa.db_id}
                  />
                )}
                {visible && (
                  <FiltrarAgenciador
                    placeholder={update.mql ? `Alterar MQL responsável (${corretoresRelate[update.mql]})` : 'MQL'}
                    style={{ height: '100%' }}
                    agenciador_obj={editMQL}
                    handleChangeAgenciador={editChangeMQL}
                  />
                )}
                {visible && (
                  <FiltrarAgenciador
                    id={'f_ag_edit'}
                    placeholder={
                      update.corretor_responsavel && corretoresRelate[update.corretor_responsavel?.db_id]
                        ? `Alterar corretor responsável (${corretoresRelate[update.corretor_responsavel?.db_id]})`
                        : 'Corretor responsável'
                    }
                    style={{ height: '100%' }}
                    agenciador_obj={editAg}
                    handleChangeAgenciador={editChangeAg}
                  />
                )}
              </Form.Item>
              <Form.Item
                initialValue={update.funil_etapa_venda !== undefined ? update.funil_etapa_venda : false}
                name="funil_etapa_venda"
                label="Etapa do Funil"
              >
                <Radio.Group>
                  <Radio value={0}>{funil_etapa_venda_nomes[0] || 'Lead'}</Radio>
                  <Radio value={1}>{funil_etapa_venda_nomes[1] || 'Atendimento'}</Radio>
                  <Radio value={2}>{funil_etapa_venda_nomes[2] || 'Agendamento'}</Radio>
                  <Radio value={3}>{funil_etapa_venda_nomes[3] || 'Visita'}</Radio>
                  <Radio value={4}>{funil_etapa_venda_nomes[4] || 'Proposta'}</Radio>
                  <Radio value={5}>{funil_etapa_venda_nomes[5] || 'Venda'}</Radio>
                  {context?.empresa?.funil_etapas_extra?.map(obj => {
                    return (
                      <Radio value={obj.id} key={'coluna_' + obj.title}>
                        {funil_etapa_venda_nomes[`coluna_${obj.id}`] || obj.title}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
              <Form.Item initialValue={update.status ? update.status : false} name="status" label="Status">
                <Radio.Group>
                  <Radio value="Perdido">Perdido</Radio>
                  <Radio value="Em desenvolvimento">Em desenvolvimento</Radio>
                  <Radio value="Ganho">Ganho</Radio>
                </Radio.Group>
              </Form.Item>
              <Button
                //@ts-ignore
                disabled={
                  loading ||
                  (context.user?.permissões.lead_editar === false && context.user.permissões.alterar_cadastro === false)
                }
                onClick={form.submit}
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
                Editar cliente
              </Button>
            </Form>
          </BasicFormWrapper>
        </AddUser>
      </div>
    </Modal>
  );
};
