import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context';
import { AutoComplete as AutoCompleteAntd } from 'antd';

export const BuscaCodigoOrigem = (props: any) => {
  const parentOnChange = props.onChange;
  const [Value, setValue] = useState('');

  const context = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${props.value}`);
      const imovel = await response.json();
      setValue('#' + imovel.codigo);
    };
    if (props.value) {
      fetchData();
    }
  }, []);

  const [Codigos, setCodigos] = useState<any[]>([]);
  const onChange = async (value: string) => {
    if (!value) {
      setCodigos([]);
      setValue('');
    } else {
      const verSomenteAgs = !context.user?.permissões.alterar_cadastro && !context.user?.permissões.imovel_ver_todos;
      const codeFilters = [
        {
          field: 'imovel.codigo',
          value: value,
          operator: 'equal',
        },
      ];

      if (verSomenteAgs && context.user?.db_id) {
        codeFilters.push({
          field: 'imovel.agenciador_id',
          value: context.user?.db_id,
          operator: 'equal',
        });
      }

      const params = new URLSearchParams({
        limit: '12',
        startAt: '0',
        empresa_id: context.empresa.db_id,
        order: JSON.stringify([]),
        filtros: JSON.stringify([codeFilters]),
      });
      const response_code = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/paginado?${params.toString()}`);
      const data_code = await response_code.json();
      const codigos = data_code.node;

      try {
        const params = new URLSearchParams({
          empresa_id: context.empresa.db_id,
          order: JSON.stringify([]),
          filtros: JSON.stringify([
            {
              field: 'imovel.codigo',
              value: value,
              operator: 'equal',
            },
          ]),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis?${params.toString()}`);
        const found_imovel = await response.json();
        const imovel_exato = found_imovel.length > 0 ? found_imovel[0] : null;
        if (imovel_exato) {
          if (
            context.user?.permissões.alterar_cadastro ||
            (verSomenteAgs && imovel_exato.agenciador_id === context.user?.db_id)
          ) {
            // não adicionar duplicadas
            if (!codigos.find(({ codigo }) => codigo === imovel_exato.codigo)) {
              codigos.unshift({ codigo: imovel_exato.codigo, key: imovel_exato.db_id });
            }
          }
        }
      } catch (error) {
        console.log('imovel_exato', error);
      }

      setCodigos(codigos.map(({ codigo, key }) => ({ imovel_id: key, value: '#' + codigo, label: '#' + codigo })));
      setValue(value);
    }
  };
  const onBlur = () => {
    if (!Value) {
      parentOnChange(null);
    }
  };
  const onClear = () => {
    setValue('');
    setCodigos([]);
    parentOnChange(null);
  };
  const onSelect = (value: string, option: any) => {
    parentOnChange(option.imovel_id);
  };
  return (
    <AutoCompleteAntd
      style={{ width: '100%' }}
      onSelect={onSelect}
      value={Value}
      onBlur={onBlur}
      allowClear
      onClear={onClear}
      options={Codigos}
      onChange={onChange}
      className="height-fix"
      placeholder="Imóvel de origem"
    />
  );
};
