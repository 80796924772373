
import React from "react";

// Context global, seria bom se eu tivese feito em typescript

const AppContext = React.createContext({
    info_empresa: {},
    /**
     * @deprecated
     */
    cache: null,
    equipes: [],
    /**
     * @type {{visto_em: boolean, tipo: 'novo_cliente' | 'lead_ag' | 'novo_imovel', related_id: string, enviado_por: null | string, created_at: Date | {seconds: number}}[]}
     */
    notificacoes: [],
    fotos_to_upload: [],
    funil_etapa_venda_nomes: {},
    set_fotos_to_upload: async (fotos) => {},
    users_empresa: [],
    unidades: [],
    empresa: {
        assinatura: {},
        titulo_site: '',
        facebook_pixel: '',
        google_code: '',
        scripts_extras: '',
        caracteristicas_disponiveis: [],
        bairros_disponiveis: [],
        db_id: "",
        banner: [],
        url: [],
        imoveis_total_tipos: {},
        logo: "",
        logo_escrita: "",
        footer_logo: "",
        redes_sociais: [],
        nome_fantasia: "",
        nome: "",
        endereço: "",
        depoimentos: [],
        tipos_disponiveis: [],
        video_topo: null,
        frase_site: "",
        utilizar_arquitetos: false,
        utilizar_construtoras: false,
        frase_lancamentos: "",
        possui_locacao: true,
        logo_use_bg: true,
        imovel_use_bg: true,
        envie_seu_imovel: false,
        ocultar_marca_agua: false,
        url_compartilhamento: '',
        palleta: {
            cor_detalhe: "",
            cor_primaria: "",
            cor_secundaria: "",
            cor_auxiliar: "",
            cor_rodape: "",
            cor_texto_rodape_1: "",
            cor_texto_rodape_2: "",
            use_color: false
        },
        tempo_para_estagnar_lead: null,
    },
    /**
     * @type {null|import("smart-imob-types").Corretor}
     */
    user: null,
});

export default AppContext;