import { Moment } from 'moment';
import React from 'react';
import { Cliente, PermissoesInterface } from 'smart-imob-types';
import styled from 'styled-components';
import { CountNClientes } from './CountNClientes';
import { WhatsIcon } from './WhatsIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';
import { Table, Popover, Empty, Popconfirm, message } from 'antd';
import PopoverConjuge from '../popovers/popover_conjuge';
import { TableWrapper } from '../../styled';
import { UserTableStyleWrapper } from '../../pages/style';
import { AvatarImovel } from './AvatarImovel';
import antdTableLocale from '../../../config/locale/antd-table';

type BuscaAvancadaInputs = {
  email: string;
  telefone: string;
  data_cadastro_min: undefined | Moment | undefined;
  data_cadastro_max: undefined | Moment | undefined;
  bant: undefined | string;
  etapa: number | undefined;
  transacao: undefined | string;
  imovel_origem: undefined | string;
  corretor_responsavel: undefined | string;
  interesse: undefined | string;
  etiquetas: string[];
};

type TabelaDeClientesProps = {
  context: any;
  CountLoading: boolean;
  permissões?: PermissoesInterface;
  rowSelection: any;
  Clientes: Cliente[];
  TableReset: boolean;
  // todo: add tipagem
  funil_etapa_venda_nomes: any;
  funil_etapas_extra: any;
  setTableReset: any;
  usersTableColumns: any;
  SearchText: any;
  showEditModal: any;
  onHandleDelete: any;
  onHandleRevive: any;
  corretoresRelate: any;
  BuscaAvancada: BuscaAvancadaInputs;
  FindProprietarios: boolean;
  adicionarModalCliente: any;
  current: number;
  pageSize: number;
  clientesTotal: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setBuscar: React.Dispatch<React.SetStateAction<boolean>>;
};

interface TagRecenteProps {
  alt?: boolean;
  home?: boolean;
}

const TagRecente = styled.div<TagRecenteProps>`
  margin-right: 4px !important;
  align-items: center;
  justify-content: center;
  background-color: #e5e5ff;
  ${props =>
    props.alt
      ? `
        background-color: #ffe7ba;
        svg {
          color: #d46b08
        }
      `
      : ''}
  ${props =>
    props.home
      ? `
        background-color: #e6fffb;
        svg {
          color: #08979c
        }
      `
      : ''}
      display: block!important;
  float: left;
  width: unset;
  border-radius: 4px;
  padding: 3px 4px 0px !important;
`;

const color_etapas: { [etapa: string]: string } = {
  '0': '#5f63f2',
  '1': '#f759ab',
  '2': '#9254de',
  '3': '#597ef7',
  '4': '#d4b106',
  '5': '#389e0d',
  '6': '#13c2c2',
  '7': '#141414',
  '9999': '#4b4b4b',
};

const bg_color_etapas: { [etapa: string]: string } = {
  '0': '#e5e5ff',
  '1': '#ffd6e7',
  '2': '#efdbff',
  '3': '#d6e4ff',
  '4': '#fff1b8',
  '5': '#d9f7be',
  '6': '#b5f5ec',
  '7': '#595959',
  '9999': '#c5c5c5',
};

const TagEtapa = styled(TagRecente)<{ etapa?: number }>`
  padding: 1px 5px 2px !important;
  color: ${props => color_etapas[String(props.etapa)] || '#5f63f2'};
  background-color: ${props => bg_color_etapas[String(props.etapa)] || '#e5e5ff'};
`;

function truncate(str: string, n: number) {
  if (!str) return '';
  return str.length > n ? str.substring(0, n - 1) + '…' : str;
}
async function copyAndNotify(txt: string, success: string) {
  await navigator.clipboard.writeText(txt);
  message.success(success);
}

export const TabelaDeClientes = (props: TabelaDeClientesProps) => {
  const {
    rowSelection,
    Clientes,
    FindProprietarios,
    CountLoading,
    usersTableColumns,
    SearchText,
    showEditModal,
    funil_etapa_venda_nomes,
    funil_etapas_extra,
    onHandleDelete,
    onHandleRevive,
    corretoresRelate,
    adicionarModalCliente,
    clientesTotal,
    pageSize,
    current,
    setCurrent,
    setPageSize,
    setBuscar,
  } = props;

  const ClientesTreated = Clientes.map(cliente => {
    let Difference_In_Days = 10;
    if (cliente.created_at && cliente.created_at.getTime) {
      const Difference_In_Time = Date.now() - cliente.created_at.getTime();
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    }
    return {
      ...cliente,
      key: cliente.db_id,
      etapa: (
        <TagEtapa
          etapa={
            !funil_etapa_venda_nomes[`coluna_${cliente.funil_etapa_venda}` || 0] &&
            (!funil_etapas_extra || !funil_etapas_extra[cliente.funil_etapa_venda || 0])
              ? 9999
              : cliente.funil_etapa_venda || 0
          }
        >
          {funil_etapa_venda_nomes[`coluna_${cliente.funil_etapa_venda}` || 0] ||
            (funil_etapas_extra ? funil_etapas_extra[cliente.funil_etapa_venda || 0] || 'Indef.' : 'Indef.')}
        </TagEtapa>
      ),
      imovel_origem: <AvatarImovel imovel_id={cliente.imovel_origem?.db_id} cliente={cliente} />,
      //email: cliente.email ? <span style={{cursor: 'pointer'}} onClick={() => copyAndNotify(cliente.email, 'Email copiado para a área de transferência')}>{truncate(cliente.email, 20)}</span> : <span style={{opacity: 0.5}}>Nenhum definido</span>,
      // nome: <Link onClick = {() => adicionarModalCliente(cliente)} title={cliente.nome} to={`${url}`}></Link>
      nome: (
        <a onClick={() => adicionarModalCliente(cliente)} title={cliente.nome}>
          {cliente.proprietario == true ? (
            <Popover title="Este cliente é um proprietário!">
              <TagRecente home>
                <FeatherIcon icon="home" size={14} />
              </TagRecente>
            </Popover>
          ) : (
            ''
          )}
          {Difference_In_Days < 2 ? (
            <Popover title="Cliente cadastrado recentemente!">
              <TagRecente>
                <FeatherIcon icon="zap" size={14} />
              </TagRecente>
            </Popover>
          ) : (
            ''
          )}
          {cliente.recadastro ? (
            <Popover
              title="Cliente recadastrado!"
              content={
                <p style={{ maxWidth: 200, marginTop: 10 }}>
                  Isso ocorre quando o lead se cadastra pelo site utilizando um email e um telefone de um cliente já
                  inserido no banco.
                </p>
              }
            >
              <TagRecente alt>
                <FeatherIcon icon="corner-up-right" size={14} />
              </TagRecente>
            </Popover>
          ) : (
            ''
          )}
          {<PopoverConjuge cliente={cliente} />}
          {truncate(cliente.nome, 20)}
        </a>
      ),
      telefone:
        (cliente.DDD && cliente.telefone) || (cliente.telefone && cliente.telefone.length >= 11) ? (
          <a
            style={{ display: 'flex', alignItems: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?phone=55${cliente.DDD || ''}${
              cliente.telefone
                ? cliente.telefone
                    .replace('-', '')
                    .replace('(', '')
                    .replace(')', '')
                : ''
            }`}
          >
            <WhatsIcon />
            {`${cliente.DDD || ''} ${cliente.telefone}`.trimLeft()}
          </a>
        ) : (
          `${cliente.DDD || ''} ${cliente.telefone || ''}`.trimLeft() || (
            <span style={{ opacity: 0.5 }}>Nenhum definido</span>
          )
        ),
      data_registro: (
        <>
          <div>
            Cadastro: {cliente.created_at ? new Date(cliente.created_at).toLocaleDateString() : 'Não registrado'}
          </div>
          <div>Edição: {cliente.edited_at ? new Date(cliente.edited_at).toLocaleDateString() : 'Não registrado'}</div>
        </>
      ),
      interesses: cliente.interesse_venda
        ? 'Venda'
        : cliente.interesse_locacao
        ? 'Locação'
        : cliente.interesse_locacao_temporada
        ? 'Temporada'
        : '',
      corretor_responsavel_nome:
        cliente.corretor_responsavel && corretoresRelate[cliente.corretor_responsavel?.db_id] ? (
          (
            <Link to={`/admin/equipe/${cliente.corretor_responsavel?.db_id}/editar`}>
              {corretoresRelate[cliente.corretor_responsavel?.db_id]}
            </Link>
          ) || <span style={{ opacity: 0.5 }}>Nenhum definido</span>
        ) : (
          <span style={{ opacity: 0.5 }}>Nenhum definido</span>
        ),
      action: (
        <div className="table-actions">
          <Button
            //@ts-ignore
            className="btn-icon"
            type="info"
            onClick={() => showEditModal(cliente, true)}
            shape="circle"
          >
            <FeatherIcon icon="user" size={16} />
          </Button>
          <Button
            //@ts-ignore
            className="btn-icon"
            type="info"
            onClick={() => showEditModal(cliente)}
            shape="circle"
          >
            <FeatherIcon icon="edit" size={16} />
          </Button>
          {(props.permissões?.lead_excluir || props.permissões?.alterar_cadastro) &&
            (!cliente.excluido ? (
              <Popconfirm
                placement="topRight"
                title="Tem certeza que quer excluir esse cliente?"
                onConfirm={() => onHandleDelete(cliente.db_id)}
                okText="Sim, excluir"
                cancelText="Não"
              >
                <Button
                  //@ts-ignore
                  className="btn-icon"
                  type="danger"
                  shape="circle"
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="topRight"
                title="Você quer restaurar esse cliente?"
                onConfirm={() => onHandleRevive(cliente.db_id)}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  //@ts-ignore
                  className="btn-icon"
                  type="info"
                  shape="circle"
                >
                  <FeatherIcon icon="skip-back" size={16} />
                </Button>
              </Popconfirm>
            ))}
        </div>
      ),
    };
  });
  return (
    <UserTableStyleWrapper>
      <div className="contact-table">
        <TableWrapper className="table-responsive" style={{ position: 'relative' }}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  image={require('../../../static/img/empty.svg')}
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    CountLoading ? (
                      <p>
                        Nenhum cliente encontrado com esse filtro até agora! <br />
                        Espere pelo fim do{' '}
                        <Popover title="Escaneamento de leads em andamento" trigger={['hover']}>
                          <b style={{ color: '#5F63F2' }}>escaneamento!</b>
                        </Popover>
                      </p>
                    ) : (
                      <span>Nenhum cliente encontrado com esse filtro!</span>
                    )
                  }
                />
              ),
            }}
            rowSelection={rowSelection}
            dataSource={ClientesTreated}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
              total: clientesTotal,
              current,
              pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
                setBuscar(true);
              },
              onChange: page => {
                setCurrent(page);
                setBuscar(true);
              },
              showTotal:
                window.screen.width > 600
                  ? (total, range) => {
                      return (
                        <>
                          <div className="search-result" style={CountLoading ? { opacity: 0.7 } : undefined}>
                            <CountNClientes
                              CountLoading={CountLoading}
                              FilteredClientes={Clientes}
                              nClientes={clientesTotal}
                              total={total}
                              range={range}
                            />
                            {CountLoading && (
                              <Popover
                                trigger={['hover']}
                                title="O que isso significa?"
                                content={
                                  <p style={{ marginTop: 8, maxWidth: 200 }}>
                                    Estamos escaneando o seu cache e retornando os clientes na medida em que eles são
                                    encontrados.
                                  </p>
                                }
                              >
                                <FeatherIcon size={18} icon="loader" />
                              </Popover>
                            )}
                          </div>
                        </>
                      );
                    }
                  : undefined,
              ...antdTableLocale,
            }}
          />
        </TableWrapper>
      </div>
    </UserTableStyleWrapper>
  );
};
