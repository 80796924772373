import { DateFromUnsafe } from "./utility/util_functions";

// Ref. em relação a possiveis melhorias de performance: https://nolanlawson.com/2021/08/22/speeding-up-indexeddb-reads-and-writes/
export class CustomIDB {
    /**
     * @type {IDBDatabase | null}
     */
    database = null;
    /**
     * @type {"imoveis" | "clientes" | "empreendimentos" | "visitas" | "fotos_upload" | "historico_empresa" | null}
     */
    ObjectStoreNome = null;
    /**
     * @type {string[]|null}
     */
    keys = null;
    use_memo = false;

    /**
     * Fazer com que a conexão com o banco seja feita toda hora e fechada tbm
     */

    /**
     * @param {"imoveis" | "clientes" | "empreendimentos" | "visitas" | "fotos_upload" | "historico_empresa"} ObjectStoreNome 
     */
    constructor(ObjectStoreNome, use_memo = true) {
        this.ObjectStoreNome = ObjectStoreNome;
        // ativar memoização para tudo menos upload de fotos.
        this.use_memo = use_memo
        //Inicializa o banco
        this.init();
    }
    /**
     * @description Essa função cria uma instancia da database do indexeddb para cada store ("imoveis", "clientes" e etc)
     * e utiliza essa database para executar as transações requisitadas (get, delete, update, add).
     * alem disso ela cria indices para cada store baseado nas nossas necessidades. lembre-se que para fazer uma mudança na estrutura
     * do banco a versão tem que ser atualizada, no momento em que eu escrevo isso a versão é a 4, caso precise adicionar um indice ou uma store
     * a versão precisa necessariamente ser maior que 4 no futuro
     */
    init = () => new Promise((resolve, reject) => {
        //const worker = new Worker();
        //worker.postMessage({a:1})
        const request = indexedDB.open('smart-imob', 7);

        request.onerror = event => {
            console.log(event)
        };
        
        request.onsuccess = event => {
            this.database = event.target.result;
            resolve(this.database);
        }

        request.onupgradeneeded = event => {
            this.database = event.target.result;

            //https://stackoverflow.com/questions/44002460/getting-object-store-already-exists-inside-onupgradeneeded/44007456
            console.log(event.oldVersion)
            if (event.oldVersion !== 0) {
                if (event.oldVersion < 7) {
                    const imoveisStore = request.transaction.objectStore('imoveis');
                    if (!imoveisStore.indexNames.contains('vendido')) {
                        imoveisStore.createIndex("vendido", "vendido", { unique: false });
                    }
                    if (!imoveisStore.indexNames.contains('created_at, ativo')) {
                        imoveisStore.createIndex("created_at, ativo", ["created_at", "ativo"], { unique: false });
                    }
                    if (!imoveisStore.indexNames.contains('preço_venda, ativo')) {
                        imoveisStore.createIndex("preço_venda, ativo", ["preço_venda", "ativo"], { unique: false });
                    }
                    if (!imoveisStore.indexNames.contains('agenciador_id, ativo')) {
                        imoveisStore.createIndex("agenciador_id, ativo", ["agenciador_id", "ativo"], { unique: false });
                    }
                    if (!imoveisStore.indexNames.contains('foto_destaque_index')) {
                        imoveisStore.createIndex("foto_destaque_index", "foto_destaque_index", { unique: false });
                    }
                    
                    const clientesStore = request.transaction.objectStore('clientes');
                    
                    if (!clientesStore.indexNames.contains('created_at, ativo')) {
                        clientesStore.createIndex("created_at, ativo", ["created_at", "ativo"], { unique: false });
                    }
                    if (!clientesStore.indexNames.contains('corretor_responsavel, ativo')) {
                        clientesStore.createIndex("corretor_responsavel, ativo", ["corretor_responsavel", "ativo"], { unique: false });
                    }
                    if (!clientesStore.indexNames.contains('nome, ativo')) {
                        clientesStore.createIndex("nome, ativo", ["nome", "ativo"], { unique: false });
                    }
                    if (!clientesStore.indexNames.contains('edited_at, ativo')) {
                        clientesStore.createIndex("edited_at, ativo", ["edited_at", "ativo"], { unique: false });
                    }

                    const empreendimentosStore = request.transaction.objectStore('empreendimentos', { keyPath: "db_id" });
                    if (!empreendimentosStore.indexNames.contains('nome')) {
                        empreendimentosStore.createIndex("nome", "nome", { unique: false });
                    }
                    return resolve(this.database)
                }
            }
        
            const imoveisStore = this.database.createObjectStore('imoveis', { keyPath: "db_id" });
            const clientesStore = this.database.createObjectStore('clientes', { keyPath: "db_id" });
            const empreendimentosStore = this.database.createObjectStore('empreendimentos', { keyPath: "db_id" });
            const visitasStore = this.database.createObjectStore('visitas', { keyPath: "db_id" });
            const fotosUploadStore = this.database.createObjectStore('fotos_upload', { keyPath: "id" });
        
            imoveisStore.createIndex("edited_at", "edited_at", { unique: false });
            imoveisStore.createIndex("created_at", "created_at", { unique: false });
            imoveisStore.createIndex("ativo", "ativo", { unique: false });

            if (!imoveisStore.indexNames.contains('foto_destaque_index')) {
                imoveisStore.createIndex("foto_destaque_index", "foto_destaque_index", { unique: false });
            }

            visitasStore.createIndex("dispositivo", "dispositivo", { unique: false });
            visitasStore.createIndex("time_ultima_visita", "time_ultima_visita", { unique: false });

            empreendimentosStore.createIndex("edited_at", "edited_at", { unique: false });
            empreendimentosStore.createIndex("created_at", "created_at", { unique: false });
            empreendimentosStore.createIndex("ativo", "ativo", { unique: false });

            if (!empreendimentosStore.indexNames.contains('nome')) {
                empreendimentosStore.createIndex("nome", "nome", { unique: false });
            }

            fotosUploadStore.createIndex("db_id", "db_id", { unique: false });

            clientesStore.createIndex("edited_at", "edited_at", { unique: false });
            clientesStore.createIndex("nome", "nome", { unique: false });
            clientesStore.createIndex("created_at", "created_at", { unique: false });
            clientesStore.createIndex("proprietario", "proprietario", { unique: false });
            clientesStore.createIndex("corretor_responsavel", "corretor_responsavel", { unique: false });
            clientesStore.createIndex("visitante_id", "visitante_id", { unique: false });
            clientesStore.createIndex("imovel_origem", "imovel_origem", { unique: false });
            clientesStore.createIndex("ativo", "ativo", { unique: false });

            if (!clientesStore.indexNames.contains('created_at, ativo')) {
                clientesStore.createIndex("created_at, ativo", ["created_at", "ativo"], { unique: false });
            }
            if (!clientesStore.indexNames.contains('corretor_responsavel, ativo')) {
                clientesStore.createIndex("corretor_responsavel, ativo", ["corretor_responsavel", "ativo"], { unique: false });
            }
            if (!clientesStore.indexNames.contains('nome, ativo')) {
                clientesStore.createIndex("nome, ativo", ["nome", "ativo"], { unique: false });
            }
            if (!clientesStore.indexNames.contains('edited_at, ativo')) {
                clientesStore.createIndex("edited_at, ativo", ["edited_at", "ativo"], { unique: false });
            }

            imoveisStore.createIndex("agenciador_id", "agenciador_id", { unique: false });
            imoveisStore.createIndex("codigo", "codigo", { unique: false });
            imoveisStore.createIndex("proprietario_id", "proprietario_id", { unique: false });
            imoveisStore.createIndex("preço_venda", "preço_venda", { unique: false });
            imoveisStore.createIndex("preço_locação", "preço_locação", { unique: false });
            if (!imoveisStore.indexNames.contains('vendido')) {
                imoveisStore.createIndex("vendido", "vendido", { unique: false });
            }
            
            if (!imoveisStore.indexNames.contains('created_at, ativo')) {
                imoveisStore.createIndex("created_at, ativo", ["created_at", "ativo"], { unique: false });
            }
            if (!imoveisStore.indexNames.contains('preço_venda, ativo')) {
                imoveisStore.createIndex("preço_venda, ativo", ["preço_venda", "ativo"], { unique: false });
            }
            if (!imoveisStore.indexNames.contains('agenciador_id, ativo')) {
                imoveisStore.createIndex("agenciador_id, ativo", ["agenciador_id", "ativo"], { unique: false });
            }
            resolve(this.database)
        };
    });
    /**
     * Função usada para ouvir eventos na store, esses eventos são feitos a mão, busque por "this.database.dispatchEvent(new CustomEvent('addMultiple', {'detail':items}))" para ter um exemplo
     * @param {"addMultiple" | "add" | "updateById" | "deleteById" | "deleteAll"} [eventName] Nome do evento para escutar
     * @param {Function} callback 
     * @returns {Function} Uma função para remover o listener
     */
    on = (eventName, callback) => {
        const listener = e => callback(e.detail)
        this.database.addEventListener(eventName, listener, false)
        return () => this.database.removeEventListener(eventName, listener, false);
    };
    /**
     * Função usada para ouvir eventos na store, esses eventos são feitos a mão, busque por "this.database.dispatchEvent(new CustomEvent('addMultiple', {'detail':items}))" para ter um exemplo
     * @param {string} [id] ID para escutar
     * @param {Function} callback 
     * @returns {Function} Uma função para remover o listener
     */
    onUpdateById = (id, callback) => {
        const listener = e => callback(e.detail)
        this.database.addEventListener('updateById:'+id, listener, false)
        return () => this.database.removeEventListener('updateById:'+id, listener, false);
    };
    /**
     * Adiciona múltiplos itens a store
     * @param {any[]} items 
     * @param {string[] | null} delete_fields
     */
    addMultiple = (items, delete_fields = null, _callback = (() => {})) => new Promise(async (resolve, reject) => {
        this.clearCaches()
        try {
            /**
             The idea behind relaxed durability is to resolve some disagreement between the browser 
             vendors as to whether IndexedDB transactions should optimize for durability (writes succeed even in 
             the event of a power failure or crash) or performance (writes succeed quickly, even if not fully flushed to disk).
             */
            let transaction = this.database.transaction(this.ObjectStoreNome, "readwrite", { durability: "relaxed" }).objectStore(this.ObjectStoreNome);
            await Promise.all(items.map((item, i) => new Promise(
                (resolve_inside, reject_inside) => {
                    //if (i === 1500 || i === 4000 || i === 8000) transaction = this.database.transaction(this.ObjectStoreNome, "readwrite").objectStore(this.ObjectStoreNome);
                    if (delete_fields) {
                        for (const field of delete_fields) {
                            delete item[field]
                        }
                    }
                    if (this.ObjectStoreNome === 'clientes') {
                        item['proprietario'] = item['proprietario'] === undefined ? 0 : item['proprietario'] ? 1 : 0;
                        item['imovel_origem'] = item['imovel_origem'] || (item['imoveis_cadastrados'] ? item['imoveis_cadastrados'][0] : null);
                    }
                    if (this.ObjectStoreNome === 'imoveis' && item.fotos && item.fotos.length) {
                        let found_destaque = null;
                        //Pega o index da menor ordem e coloca no found_destaque
                        const { idx } = item.fotos.reduce((prev, curr, idx) => {
                            let currOrdem = Number(curr.ordem)
                            if ((currOrdem < prev.ordem && prev.destaque === false) || curr.destaque === true) {
                                return {
                                    idx,
                                    ordem: currOrdem,
                                    destaque: !!curr.destaque
                                }
                            }
                            return prev;
                        }, {idx: 0, ordem: Infinity, destaque: false})
                        found_destaque = idx;
                        item['foto_destaque_index'] = found_destaque;
                    }
                    const request = transaction.put({
                        ...item,
                        created_at: item.created_at ? DateFromUnsafe(item.created_at) : null,
                        edited_at: item.edited_at ? DateFromUnsafe(item.edited_at) : null,
                        ativo: item.excluido ? 0 : 1,
                        vendido: item.vendido ? 1 : 0
                    });
                    request.onerror = event => {
                        reject_inside(event.target.result)
                    };
                    request.onsuccess = event => {
                        const result = event.target.result
                        _callback()
                        resolve_inside(result);
                    };
                })
            ))
            this.database.dispatchEvent(new CustomEvent('addMultiple', {'detail':items}))
            resolve(items.length)
        } catch (error) {
            reject(error)
        }
    })
    /**
     * Adiciona um item único a store
     * @param {any} item 
     */
    add = item => new Promise((resolve, reject) => {
        this.clearCaches()
        const transaction = this.database.transaction(this.ObjectStoreNome, "readwrite");
        
        if (this.ObjectStoreNome === 'clientes') {
            item['proprietario'] = item['proprietario'] === undefined ? 0 : item['proprietario'] ? 1 : 0;
            item['imovel_origem'] = item['imovel_origem'] || (item['imoveis_cadastrados'] ? item['imoveis_cadastrados'][0] : null);
        }
        if (this.ObjectStoreNome === 'imoveis' && item.fotos && item.fotos.length) {
            let found_destaque = null;
            //Pega o index da menor ordem e coloca no found_destaque
            const { idx } = item.fotos.reduce((prev, curr, idx) => {
                let currOrdem = Number(curr.ordem)
                if ((currOrdem < prev.ordem && prev.destaque === false) || curr.destaque === true) {
                    return {
                        idx,
                        ordem: currOrdem,
                        destaque: !!curr.destaque
                    }
                }
                return prev;
            }, {idx: 0, ordem: Infinity, destaque: false})
            found_destaque = idx;
            item['foto_destaque_index'] = found_destaque;
        }
        const request = transaction.objectStore(this.ObjectStoreNome).add({
            ...item,
            // @todo: e viavel usar datefromusafe aqui?
            created_at: item.created_at ? item.created_at instanceof Date ? item.created_at : typeof item.created_at === 'string' ? new Date(item.created_at) : item.created_at.toDate ? item.created_at.toDate() : null : null,
            edited_at: item.edited_at ? item.edited_at instanceof Date ? item.edited_at : typeof item.edited_at === 'string' ? new Date(item.edited_at) :  item.edited_at.toDate ? item.edited_at.toDate() : null : null,
            vendido: item.vendido ? 1 : 0,
            ativo: item.excluido ? 0 : 1
        });
        request.onerror = event => reject(event.target.result);
        request.onsuccess = event => {
            const result = event.target.result
            resolve(result);
            this.database.dispatchEvent(new CustomEvent('add', {'detail':result}))
        };
    })
    /**
     * Retorna todos os itens da store, considere usar o getAllNative pq ele é mais rápido
     * @deprecated
     * @returns {Promise<any[]>}
     */
    __DEPRECATED__getAll = () => new Promise((resolve, reject) => {
        const end_result = [];
        const cursor = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).openCursor();
        cursor.onsuccess = event => {
            const { result } = event.target;
            if (result) {
                end_result.push(result.value);
                result.continue();
            } else {
                resolve(end_result)
            }
        }
        cursor.onerror = event => reject(event.target.result)
    });
    /**
     * Retorna todas as keys nessa store
     */
    getAllKeys = () => new Promise((resolve, reject) => {
        const request = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).getAllKeys();
        request.onsuccess = event => {
            this.keys = event.target.result
            return resolve(this.keys);
        };
        request.onerror = event => reject(event.target.result);
    });
    // Ref.: https://dev.to/carlillo/understanding-javascripttypescript-memoization-o7k
    memoization = (fn, cache_name) => {
        return (..._args) => {
            if (!this.use_memo) {
                return fn(..._args)
            }
            const args = _args.map(arg => {
                if (typeof arg === 'function') return arg.toString()
                return arg
            })
            const str_args = JSON.stringify(args)
            return (this.caches[cache_name][str_args] = typeof this.caches[cache_name][str_args] === 'undefined' ? fn(..._args) : this.caches[cache_name][str_args])
        }
    }
    getAllNative = () => new Promise((resolve, reject) => {
        const request = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).getAll();
        request.onsuccess = event => resolve(event.target.result);
        request.onerror = event => reject(event.target.result);
    });
    caches = {
        // @todo: Talvez é possivel inferir o cache do getAll caso o getAllByIndex [ "ativo", 1 ] já tenha sido descoberto, fazer uma call para getAllByIndex [ "ativo", 1 ] e merger os dois deve retornar o getAll [], porem a ordem deve ser diferente...
        // @todo: Ao inves de chamar clearCaches com as funções de update talvez seja possivel alterar direto nesses objetos de cache para nn resetar a memoização
        getAll: {},
        getAllFiltered: {},
        getById: {},
        getMultipleById: {},
        getAllByIndex: {},
        getAllByKeyCursor: {},
        getFieldsByCursor: {},
        getAllByIndexFilter: {},
        getMultipleByIdMapReturn: {},
        // o cache de streamAllByIndex apenas cacheia os primeiros imóveis
        streamAllByIndex: {}
    }
    clearCaches = () => {
        for (const key in this.caches) {
            this.caches[key] = {}
        }
    }
    /**
     * Retorna todos os itens da store, considere usar o getAllNative pq ele é mais rápido
     * @type {() => Promise<any>}
     * @returns {Promise<any[]>}
     */
    getAll = this.memoization(this.getAllNative, 'getAll')
    /*
    //Protóripo falhado
    __fasterGetAll__ = async () => {
        try {
            //5000 Imóveis com getAll: (cold 3.3) 2.7 ~ 2.6 segundos
            //5000 Imóveis com __fasterGetAll__: (cold 2.9) 2.5 segundos
            //5000 Imóveis com __fasterGetAll__ com cache: (cold 2.8) 1.8 segundos

            //5000 Imóveis com getAllKeys: ~111 ms  
            
            //cache
            if (!this.keys) {
                this.keys = await this.getAllKeys();
            }
            return await this.getMultipleById(this.keys);
        } catch (error) {
            console.error(error);
            return [];
        }
    };*/
    /**
     * Retorna uma promise que retorna true caso a store tenha pelo menos um item
     * @returns {Promise<boolean>}
     */
    getOne = () => new Promise((resolve, reject) => {
        if (this.keys && this.keys.length > 0) return resolve(true);
        const cursor = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).openCursor();
        cursor.onsuccess = event => {
            const { result } = event.target;
            if (result) {
                resolve(true)
            } else resolve(false)
        }
        cursor.onerror = event => reject(event.target.result)
    });
    /**
     * Retorna uma promise que retorna true caso a store tenha pelo menos um item, utiliza filtro
     * @returns {Promise<boolean>}
     */
    getOneFiltered = (filter_function) => new Promise((resolve, reject) => {
        if (this.keys && this.keys.length > 0) return resolve(true);
        const cursor = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).openCursor();
        cursor.onsuccess = event => {
            const { result } = event.target;
            if (!result) return resolve(false)
            if (filter_function(result.value)) {
                resolve(result.value)
            } else {
                result.continue();
            }
        }
        cursor.onerror = event => reject(event.target.result)
    });
    /**
     * Função que filtra todos os itens na store durante o get, removendo a necessidade de um filter após o get
     * @type {(filter_function: Function) => Promise<any>}
     * @param {Function} filter_function 
     */
    getAllFiltered = filter_function => new Promise(async (resolve, reject) => {
        const hasGetAllCache = !!this.caches.getAll['[]']
        if (hasGetAllCache) {
            const all = await this.getAll()
            return resolve(all.filter(filter_function))
        }
        // Array com os resultados que passaram no filtro
        const end_result = [];
        // Array com todos resultados, mesmo os que não passaram no filtro. esses valores vao para o cache do getAll
        const all_result = [];
        const cursor = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).openCursor();
        cursor.onsuccess = event => {
            const { result } = event.target;
            if (result) {
                if (filter_function(result.value)) {
                    end_result.push(result.value);
                }
                if (!hasGetAllCache) {
                    all_result.push(result.value)
                }
                result.continue();
            } else {
                if (!hasGetAllCache) {
                    this.caches.getAll['[]'] = new Promise((resolve_cache_inject) => resolve_cache_inject(all_result));
                }
                resolve(end_result)
            }
        }
        cursor.onerror = event => reject(event.target.result)
    })
    /**
     * Busca por um item baseado no seu db_id ou id
     * @type {(id: string) => Promise<any>}
     * @param {string} id 
     */
    getById = this.memoization(id => new Promise((resolve, reject) => {
        const request = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).get(id);
        request.onerror = event => reject(event.target.result);
        request.onsuccess = event => resolve(event.target.result || null);
    }), 'getById');
    
    /**
     * Atualiza multiplos itens baseados nos seus ids
     * @param {{id: string, change: any}[]} IdChangeArray 
     */
    updateMultipleById = (IdChangeArray) => new Promise(async (resolve, reject) => {
        this.clearCaches()
        try {
            const objectStore = this.database.transaction(this.ObjectStoreNome, 'readwrite').objectStore(this.ObjectStoreNome);
    
            const end_result = await Promise.all(IdChangeArray.filter(({id, change}) => id && change).map(({id, change}) => new Promise(async (resolve_inside, reject_inside) => {
                const get_result = await new Promise((resolve_get, reject_get) => {
                    const request = objectStore.get(id);
                    request.onerror = event => reject_get(event.target.result);
                    request.onsuccess = event => resolve_get(event.target.result || null);
                })

                const new_item = {...get_result, ...change};
                const request_update = objectStore.put(new_item);
                request_update.onerror = event => reject_inside(event.target.result);
                request_update.onsuccess = _ => resolve_inside(new_item);
            })));
            
            resolve(end_result.filter(item => item)); //Remover nulls
        } catch (error) {
            console.error(error)
            reject([])
        }
    });
    
    /**
     * Busca multiplos itens baseado em uma lista de ids
     * @type {(IdArray: string[]) => Promise<any[]>}
     * @param {Array<string>} IdArray 
     */
    getMultipleById = this.memoization((IdArray) => new Promise(async (resolve, reject) => {
        try {
            const objectStore = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome);
    
            const end_result = await Promise.all(IdArray.map(id => new Promise((resolve_inside, reject_inside) => {
                const request = objectStore.get(id);
                request.onerror = event => reject_inside(event.target.result);
                request.onsuccess = event => resolve_inside(event.target.result || null);
            })));
            
            resolve(end_result.filter(item => item)); //Remover nulls
        } catch (error) {
            console.error(error)
            reject([])
        }
    }), 'getMultipleById');

    
    /**
     * Busca multiplos itens baseado em uma lista de ids
     * @type {(IdArray: string[]) => Promise<Map<string, any>>}
     * @param {Array<string>} IdArray 
     */
     getMultipleByIdMapReturn = this.memoization((IdArray) => new Promise(async (resolve, reject) => {
        try {

            // To avoid slowing things down, don't open a readwrite transaction unless you actually need to write into the database.

            const objectStore = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome);
            const return_map = new Map();
            await Promise.all(IdArray.map(id => new Promise((resolve_inside, reject_inside) => {
                const request = objectStore.get(id);
                request.onerror = event => reject_inside(event.target.result);
                request.onsuccess = event => {
                    const res = event.target.result
                    resolve_inside(res || null)
                    if (res) {
                        return return_map.set(res.db_id, res)
                    }
                };
            })));
            
            resolve(return_map); //Remover nulls
        } catch (error) {
            console.error(error)
            reject([])
        }
    }), 'getMultipleByIdMapReturn');

    /**
     * Atualiza um item com as mudanças no parametro "changes"
     * @param {string} id 
     * @param {any} changes 
     */
    updateById = (id, changes) => new Promise((resolve, reject) => {
        this.clearCaches()
        const objectStore = this.database.transaction(this.ObjectStoreNome, 'readwrite').objectStore(this.ObjectStoreNome)
        const request = objectStore.get(id);
        request.onerror = event => reject(event.target.result);
        request.onsuccess = event => {
            let data = event.target.result;
            
            if (!data) return resolve(null)


            if (this.ObjectStoreNome === 'imoveis' && changes.fotos && changes.fotos.length) {
                let found_destaque = null;
                //Pega o index da menor ordem e coloca no found_destaque
                const { idx } = changes.fotos.reduce((prev, curr, idx) => {
                    let currOrdem = Number(curr.ordem)
                    if ((currOrdem < prev.ordem && prev.destaque == false) || curr.destaque === true) {
                        return {
                            idx,
                            ordem: currOrdem,
                            destaque: !!curr.destaque
                        }
                    }
                    return prev;
                }, {idx: 0, ordem: Infinity, destaque: false})
                found_destaque = idx;
                data['foto_destaque_index'] = found_destaque;
            } else {
                data['foto_destaque_index'] = null
            }
            
            for (const key in changes) {
                if (changes.hasOwnProperty(key)) {
                    const value = changes[key];
                    if ((key === 'edited_at' || key === 'created_at') && value && value.toDate) {
                        // é viavel usar date from unsafe aqui?
                        data[key] = value.toDate();
                    } else if (key === 'proprietario' || key === 'ativo') {
                        data[key] = value ? 1 : 0;
                    } else if (key === 'excluido') {
                        data[key] = value;
                        data['ativo'] = value ? 0 : 1;
                    } else if (key === 'vendido') {
                        data['vendido'] = value ? 1 : 0;
                    }
                    else {
                        data[key] = value;
                    }
                }
            }

            const request_update = objectStore.put(data);
            request_update.onerror = event => reject(event.target.result);
            request_update.onsuccess = _ => {
                if (data && (data.db_id || data.key)) {
                    this.database.dispatchEvent(new CustomEvent('updateById:'+(data.db_id || data.key), {'detail':data}))
                }
                return resolve(data)
            };
        };
    });
    /**
     * Remove um item baseado no id
     * @param {string} id 
     */
    deleteById = id => new Promise((resolve, reject) => {
        this.clearCaches()
        const request = this.database.transaction(this.ObjectStoreNome, 'readwrite').objectStore(this.ObjectStoreNome).delete(id);
        request.onsuccess = event => resolve(event.target.result);
        request.onerror = event => reject(event.target.result);
    });
    deleteAll = () => new Promise((resolve, reject) => {
        this.clearCaches()
        const objectStore = this.database.transaction(this.ObjectStoreNome, 'readwrite').objectStore(this.ObjectStoreNome)
        const pdestroy = objectStore.clear(); 
        pdestroy.onsuccess = () => {
            resolve(0)
        }
        pdestroy.onerror = event => reject(event.target.result)
    });
    /**
     * @type {(use_index: string, index_value: any, key_range?: IDBKeyRange) => Promise<any[]>}
     * @param {string} use_index 
     * @param {any} index_value 
     * @param {IDBKeyRange} key_range
     */
    getAllByIndex = this.memoization((use_index, index_value, key_range = null) => new Promise(async (resolve, reject) => {
        if (!this.database) await this.init();
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(use_index);
        const request = index.getAll(key_range || IDBKeyRange.only(index_value));
        request.onsuccess = event => resolve(event.target.result)
        request.onerror = event => reject(event.target.result)
    }), 'getAllByIndex');

    
    /**
     * @param {(batch_result:any[], done: boolean) => any} batch_callback 
     * @param {number} initial_batch 
     * @param {number} batch_size
     * @return {() => Promise<Function>} Um callback para abortar a transação
     */
     streamAll = (batch_callback, initial_batch = 40, batch_size = 1000) => async () => {
        if (!this.database) await this.init();
        const hasGetAllCache = !!this.caches.getAll['[]']
        if (hasGetAllCache) {
            const all = await this.getAll()
            batch_callback(all, true)
            return () => {}
        }
        const transaction = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome);
        const request = transaction.openCursor();
        const end_result = []
        let has_init = false
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                if ((end_result.push(cursor.value) % (has_init ? batch_size : initial_batch)) === 0) {
                    has_init = true
                    batch_callback(end_result, false)
                }
                cursor.continue();
            } else {
                batch_callback(end_result, true)
            }
        }
        return () => {
            try {
                request.transaction.abort()
            } catch (error) {
                console.log('streamAll foi chamado com erro', error)
            }
        }
    };

    /**
     * @param {(batch_result:any[], done: boolean, is_first: boolean) => any} batch_callback 
     * @param {number} [initial_batch] 
     * @param {number} [batch_size]
     * @param {string} [memo_name] Indentificador de memoização `DEPRECADO - Para impedir vazamentos de memória`
     * @param {(keys:any|any[]) => boolean} [index_filter]
     * @param {(value:any) => boolean} [value_filter]
     * @return {(use_index: string, query: IDBValidKey | IDBKeyRange | null, direction?: IDBCursorDirection) => Promise<Function>} Um callback para abortar a transação
     */
    streamAllByIndex = (batch_callback, initial_batch = 40, batch_size = 1000, memo_name, index_filter, value_filter) => (use_index, query, direction) => {
        console.time(memo_name)
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(use_index);
        const request = index.openCursor(query, direction);
        const end_result =  []
        let has_init = false
        request.onerror = console.log
        request.onsuccess = event => {
            const cursor = event.target.result;
            try {
                if (cursor) {
                    // filtro baseados nos indexes, é preferido utilizar os filtros aqui quando possivel
                    if (index_filter && !index_filter(cursor.key)) {
                        cursor.continue();
                    } else {
                        // filtro baseado em valores
                        if (value_filter && !value_filter(cursor.value)) {
                            cursor.continue();
                        } else {
                            // adiciona o valor na end_result, caso o index do item seja divisivel por batch_size ou initial_batch acionar o callback para comunicar o client side
                            if ((end_result.push(cursor.value) % (has_init ? batch_size : initial_batch)) === 0) {
                                batch_callback(end_result, false, has_init === false)
                                if (has_init === false) {
                                    console.timeEnd(memo_name)
                                }
                                has_init = true
                            }
                            cursor.continue();
                        }
                    }
                } else {
                    batch_callback(end_result, true, has_init === false)
                }
            } catch (error) {
                // Uncaught DOMException: Failed to execute 'continue' on 'IDBCursor': The transaction has finished.
                // ocorre quando tenta matar a transaction enquanto ela ta rodando
                console.log('Transação morta manualmente')
                batch_callback(end_result, true, has_init === false)
            }
            
        }
        return () => {
            try {
                request.transaction.abort()
            } catch (error) {
                // @todo: separar a transaction em uma variavel e colocar o oncompete nela pra determinar se é possivel abortar ou não (Ref.: https://stackoverflow.com/questions/17847665/how-can-i-know-if-an-indexeddb-request-loop-has-ended-or-not )
                console.log('abort streamAllByIndex foi chamado com erro (provavelmente foi fechado após a transação já ter sido finalizada)', {error})
            }
        }
    };

    /**
     * @param {string} use_index 
     * @param {any} index_value 
     * @param {IDBKeyRange} key_range
     */
    getOneByIndex = (use_index, index_value, key_range = null) => new Promise(async (resolve, reject) => {
        if (!this.database) await this.init();
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(use_index);
        const request = index.openCursor(key_range || IDBKeyRange.only(index_value));
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                return resolve(cursor.value)
            } else resolve(null)
        }
        request.onerror = event => reject(event.target.result)
    });
    
    getByLastDate = (campo, back_c_init = 5) => new Promise((resolve, reject) => {
        let back_c = back_c_init || 3;
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(campo);
        const request = index.openCursor(null, 'prev');
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                const val = cursor.value;
                if (val[campo] instanceof Date) {
                    back_c--
                    if (back_c === 0) {
                        resolve(val)
                    } else {
                        cursor.continue()
                    }
                } else {
                    cursor.continue()
                };
            } else resolve(null)
        }
        request.onerror = event => reject(event.target.result)
    });
    getByFirstDate = campo => new Promise((resolve, reject) => {
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(campo);
        const request = index.openCursor(null, 'next');
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                const val = cursor.value;
                if (val[campo] instanceof Date) {
                    resolve(val)
                } else {
                    cursor.continue()
                };
            } else resolve(null)
        }
        request.onerror = event => reject(event.target.result)
    });

    /**
     * Cria um cursor que percorre toda a store em busca de um index
     * @see https://developer.mozilla.org/pt-BR/docs/Web/API/IDBCursor
     * @type {(campo: string) => Promise<any[]>}
     * @param {string} [campo] Campo que vai retornar os dados junto do id dos itens 
     */
    getAllByKeyCursor = this.memoization(campo => new Promise(async (resolve, reject) => {
        
        // Talvez usar isso aqui possa ser overkill e PERIGOSO (não achei nenhuma documentação relacionada aos retornos do cursor usando index, acredito que valores undefined e null nunca sejam retornados mas é preciso ter uma confirmação) por enquanto a memoizacao é suficiente, talvez ativar apos ter os testes rodando corretamente
        /** 
        const hasGetAllCache = !!this.caches.getAll['[]']
        if (hasGetAllCache) {
            const all = await this.getAll()
            return resolve(
                all
                .filter(item => item[campo] !== null && item[campo] !== undefined)
                .map((item) => ({ [campo]: item[campo], key: item.db_id }))
            )
        }*/
        const index = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome).index(campo);
        const request = index.openKeyCursor(null, 'prev');
        const end_result = [];
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                end_result.push({[campo]: cursor.key, key: cursor.primaryKey})
                cursor.continue();
            } else resolve(end_result)
        }
        request.onerror = event => reject(event.target.result)
    }), 'getAllByKeyCursor')

    
    /**
     * Cria um cursor com um limite que percorre toda a store em busca de documentos com index normalizados semelhantes
     * @see https://developer.mozilla.org/pt-BR/docs/Web/API/IDBCursor
     * @param {string} [campo] Campo que vai retornar os dados junto do id dos itens 
     * @param {string} [input] String enviada para comparação 
     * @param {number} [limit] Limite de itens retornados 
     * @returns {Promise<{ [campo: string]: string, key: string }[]>}
     */
     findByNormalizedStringIndex = (campo, input, limit = 12) => new Promise((resolve, reject) => {
        const normalized_input = input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const index = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome).index(campo);
        const request = index.openKeyCursor(null, 'prev');
        const end_result = [];
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                if (cursor.key && typeof cursor.key === 'string' && cursor.key.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normalized_input)) {
                    // Impedir repetição
                    if (!end_result.find(r => r[campo] === cursor.key)) {
                        const l = end_result.push({[campo]: cursor.key, key: cursor.primaryKey})
                        if (l === limit) return resolve(end_result)
                    }
                }
                cursor.continue();
            } else resolve(end_result)
        }
        request.onerror = event => reject(event.target.result)
    })

    
    /**
     * Cria um cursor com um limite que percorre toda a store em busca de documentos com index normalizados semelhantes
     * @see https://developer.mozilla.org/pt-BR/docs/Web/API/IDBCursor
     * @param {string} [campo] Campo que vai retornar os dados junto do id dos itens 
     * @param {string} [input] String enviada para comparação 
     * @param {number} [limit] Limite de itens retornados 
     * @param {Function} [filter] Função de retorno true ou false para validar os dados do item 
     * @returns {Promise<{ [campo: string]: string, key: string }[]>}
     */
    findByNormalizedStringIndexFiltered = (campo, input, limit = 12, filter) => new Promise((resolve, reject) => {
        const normalized_input = input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const index = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome);
        const request = index.openCursor();
        const end_result = [];
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                if (cursor.key && typeof cursor.key === 'string' && cursor.key.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normalized_input)) {
                    const cursorValue = cursor.value;
                    if (filter(cursorValue)) {
                        // Impedir repetição
                        if (!end_result.find(r => r[campo] === cursor.key)) {
                            const l = end_result.push({[campo]: cursor.key, key: cursor.primaryKey})
                            if (l === limit) return resolve(end_result)
                        }
                    }
                }
                cursor.continue();
            } else resolve(end_result)
        }
        request.onerror = event => reject(event.target.result)
    })

    /**
     * Cria um cursor que percorre toda a store em busca de um campo
     * @see https://developer.mozilla.org/pt-BR/docs/Web/API/IDBCursor
     * @type {(...campos: any[]) => Promise<any[]>}
     * @param {string[]} [campos] Campo que vai retornar os dados junto do id dos itens 
     */
    getFieldsByCursor = this.memoization((...campos) => new Promise(async (resolve, reject) => {
        const hasGetAllCache = !!this.caches.getAll['[]']
        if (hasGetAllCache) {
            const all = await this.getAll()
            return resolve(
                all.map((item) => campos.reduce((prev, campo) => { 
                    prev[campo] = item[campo];
                    return prev 
                }, { key: item.db_id }))
            )
        }
        const index = this.database.transaction(this.ObjectStoreNome, 'readonly').objectStore(this.ObjectStoreNome)
        const request = index.openCursor();
        const end_result = [];
        const all_result = [];
        if (campos.length > 1) {
            request.onsuccess = event => {
                /**
                 * @type {IDBCursor}
                 */
                const cursor = event.target.result;
                if (cursor) {
                    const cursorValue = cursor.value;
                    const objFound = {
                        key: cursor.primaryKey
                    }
                    for (const key of campos) {
                        objFound[key] = cursorValue[key]
                    }
                    end_result.push(objFound)
                    // if (!hasGetAllCache) {
                    //     all_result.push(cursorValue)
                    // }
                    cursor.continue();
                } else {
                    // if (!hasGetAllCache) {
                    //     this.caches.getAll['[]'] = new Promise((resolve_cache_inject) => resolve_cache_inject(all_result));
                    // }
                    resolve(end_result)
                }
            }
        } else {
            const campo = campos[0];
            request.onsuccess = event => {
                /**
                 * @type {IDBCursor}
                 */
                const cursor = event.target.result;
                if (cursor) {
                    const cursorValue = cursor.value;
                    end_result.push({[campo]: cursorValue[campo], key: cursor.primaryKey})
                    // if (!hasGetAllCache) {
                    //     all_result.push(cursorValue)
                    // }
                    cursor.continue();
                } else {
                    // if (!hasGetAllCache) {
                    //     this.caches.getAll['[]'] = new Promise((resolve_cache_inject) => resolve_cache_inject(all_result));
                    // }
                    resolve(end_result)
                }
            }
        }
        request.onerror = event => reject(event.target.result)
    }), 'getFieldsByCursor');

    /**
     * seleciona o com maior numero
     */
    getMaxByIndex = campo => new Promise((resolve, reject) => {
        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(campo);
        const request = index.openCursor(null, 'prev');
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                resolve(cursor.value)
            } else resolve(null)
        }
        request.onerror = event => reject(event.target.result)
    });

    getOnlyAtivos = async () => this.getAllByIndex('ativo', 1);
    getOnlyInativos = async () => this.getAllByIndex('ativo', 0);
    
    /**
     * Cria um cursor que percorre todo o index selecionado e retorna os itens que sobrevivem a função de filtro, ideal para fazer buscas no banco inteiro com um filtro complexo. Alternativa mais performante: findByNormalizedStringIndexFiltered
     * @param {string} use_index 
     * @param {any} index_value 
     * @param {Function} filter_function 
     * @param {null | IDBKeyRange} key_range 
     * @type {(use_index: string, index_value: any, filter_function: Function, key_range?: null | IDBKeyRange) => Promise<any[]>}
     */
    getAllByIndexFilter = (use_index, index_value, filter_function, key_range = null) => new Promise(async (resolve, reject) => {
        // remover indice 2 caso key_range for null
        const input_check = [use_index, index_value, key_range];
        if (input_check[2] === null) {
            input_check.pop()
        }
        
        const str_args = JSON.stringify(input_check)
        const hasGetByIndex = !!this.caches.getAllByIndex[str_args]

        if (hasGetByIndex) {
            const all = await this.getAllByIndex(...input_check)
            return resolve(all.filter(filter_function))
        }

        const index = this.database.transaction(this.ObjectStoreNome).objectStore(this.ObjectStoreNome).index(use_index);
        const request = index.openCursor(key_range || IDBKeyRange.only(index_value));
        const end_result = [];
        const all_result = [];

        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                if (filter_function(cursor.value)) {
                    end_result.push(cursor.value);
                }
                if (!hasGetByIndex) {
                    all_result.push(cursor.value)
                }
                cursor.continue();
            } else {
                if (!hasGetByIndex) {
                    this.caches.getAllByIndex[str_args] = new Promise((resolve_cache_inject) => resolve_cache_inject(all_result));
                }
                resolve(end_result)
            }
        }
        request.onerror = event => reject(event.target.result)
    });

    getOnlyAtivosFilter = filter_function => this.getAllByIndexFilter('ativo', 1, filter_function);
    getOnlyInativosFilter = filter_function => this.getAllByIndexFilter('ativo', 0, filter_function);

}

const SmartCache = {
    imoveis: new CustomIDB('imoveis'),
    empreendimentos: new CustomIDB('empreendimentos'),
    clientes: new CustomIDB('clientes'),
    visitas: new CustomIDB('visitas'),
    fotos_upload: new CustomIDB('fotos_upload', false),

    //nomemo_clientes: new CustomIDB('clientes', false) 
}

export default SmartCache