import React, { useContext, useState } from 'react';
import { Row, Checkbox, message, Form, Input, Select } from 'antd';
import { Cards } from '../../cards/frame/cards-frame';
import { Button } from '../../buttons/buttons';
import AppContext from '../../../context';
import { Corretor } from 'smart-imob-types';
import { ChangePasswordModal } from '../change-password';
import { UserDeleteModal } from '../delete';
import { WhatsAppOutlined } from '@ant-design/icons';

interface EditarPerfilProps {
  usuario: Corretor;
  meuPerfil: boolean;
  isAdmin: boolean;
}

export const EditarPerfil = ({ usuario, meuPerfil, isAdmin }: EditarPerfilProps) => {
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [openModalRemover, setOpenModalRemover] = useState(false);
  const [openModalAlterarSenha, setOpenModalAlterarSenha] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    setLoading(true);
    let acesso_venda = false;
    let acesso_locacao = false;

    if (values.acesso === 'venda') {
      acesso_venda = true;
      acesso_locacao = false;
    } else if (values.acesso === 'locacao') {
      acesso_venda = false;
      acesso_locacao = true;
    } else if (values.acesso === 'ambos') {
      acesso_venda = true;
      acesso_locacao = true;
    }

    delete values.acesso;

    const payload = {
      ...values,
      acesso_venda,
      acesso_locacao,
    };

    try {
      if (values.email !== usuario.email && usuario.email) {
        payload.email = values.email;
      }
      if (values.telefone) {
        payload.telefone = values.telefone.replace(/\./g, '').replace(/\+/g, '');
      }
      if (values.nome !== usuario.nome && usuario.nome) {
        payload.nome = values.nome;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/${usuario.db_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Falha ao restaurar o cliente');
      }

      message.success('Dados editados com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao editar esses dados!');
    } finally {
      setLoading(false);
    }
  };

  const sendTestWhatsAppMessage = async () => {
    setLoading(true);
    try {
      const gerarDinamico = () => {
        const letras = Math.random()
          .toString(36)
          .substring(2, 7)
          .toUpperCase();
        const numeros = Math.floor(Math.random() * 100000)
          .toString()
          .padStart(5, '0');
        return letras + numeros;
      };

      await fetch(`${process.env.REACT_APP_API_URI}/notificacoes/teste-whatsapp`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuario: context.user,
          cliente: {
            db_id: gerarDinamico(),
            titulo: `SMTX Teste - ${gerarDinamico()}`,
            created_at: new Date(),
            telefone: gerarDinamico(),
            DDD: gerarDinamico(),
            imovel_origem: {
              titulo: `Imóvel de Teste - ${gerarDinamico()}`,
            },
            corretor_responsavel: {
              db_id: gerarDinamico(),
              nome: context.empresa.nome,
            },
            formulario: {
              name: `Formulário - ${gerarDinamico()}`,
            },
          },
        }),
      });
      message.success('Mensagem de teste enviada com sucesso!');
    } catch (error) {
      message.error('Erro ao enviar mensagem de teste.');
    }
    setLoading(false);
  };

  return (
    <Cards title="Edite suas informações">
      <UserDeleteModal
        open={openModalRemover}
        setOpen={setOpenModalRemover}
        userId={usuario.db_id}
        empresaId={context.empresa?.db_id}
      />
      <ChangePasswordModal open={openModalAlterarSenha} setOpen={setOpenModalAlterarSenha} userId={usuario.db_id} />
      <Row gutter={15} style={{ justifyContent: 'center' }}>
        {usuario ? (
          <Form form={form} name="register" onFinish={handleSubmit} layout="vertical" style={{ maxWidth: 900 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '50px' }}>
              <Form.Item label="Usuário (Apelido)" name="user_apelido" initialValue={usuario.user_apelido || ''}>
                <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="Usuário" />
              </Form.Item>
              <Form.Item required label="Nome" name="nome" initialValue={usuario.nome || ''}>
                <Input
                  disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                  placeholder="Nome completo"
                />
              </Form.Item>
              <Form.Item required name="email" label="Email" initialValue={usuario.email || ''}>
                <Input
                  disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                  placeholder="seu@email.com"
                />
              </Form.Item>
              <Form.Item name="instagram" label="Instagram" initialValue={usuario.instagram || ''}>
                <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="Instagram" />
              </Form.Item>
              <Form.Item label="Cargo" name="cargo" initialValue={usuario.cargo || ''}>
                <Input placeholder="Cargo do funcionário" />
              </Form.Item>
              <Form.Item
                required
                label="Setor"
                name="acesso"
                initialValue={
                  usuario.acesso_venda && usuario.acesso_locacao
                    ? 'ambos'
                    : usuario.acesso_locacao
                    ? 'locacao'
                    : 'venda'
                }
              >
                <Select
                  size="large"
                  placeholder="Selecione o setor que o usuário terá acesso"
                  style={{ width: '100%', marginBottom: 20 }}
                  dropdownMatchSelectWidth={200}
                >
                  <Select.Option value="venda">Venda</Select.Option>
                  <Select.Option value="locacao">Locação</Select.Option>
                  <Select.Option value="ambos">Ambos</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item required label="Telefone" name="telefone" initialValue={usuario.telefone || ''}>
                <Input disabled={!context.user?.permissões.alterar_cadastro} placeholder="Telefone" />
              </Form.Item>
              <Form.Item name="CRECI" label="CRECI" initialValue={usuario.CRECI || ''}>
                <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="CRECI" />
              </Form.Item>
              <Form.Item name="CPF" label="CPF" initialValue={usuario.CPF || ''}>
                <Input disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro} placeholder="CPF" />
              </Form.Item>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                <Form.Item name="bio" label="BIO" initialValue={usuario.bio || ''}>
                  <Input.TextArea
                    disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}
                    placeholder="Bio do Funcionário"
                  />
                </Form.Item>
                <Form.Item
                  name="receber_notificacao"
                  initialValue={!!usuario.receber_notificacao}
                  valuePropName={'checked'}
                >
                  <Checkbox disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}>
                    Receber notificações no aplicativo e no sistema web!
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            <Form.Item name="aparecer_site" initialValue={!!usuario.aparecer_site} valuePropName={'checked'}>
              <Checkbox disabled={!meuPerfil && !context.user?.permissões.alterar_cadastro}>
                Disponibilizar parceiro no site
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={(!meuPerfil && !isAdmin) || loading}
                onClick={form.submit}
                className="btn-create"
                htmlType="submit"
                type="primary"
                size="large"
              >
                Atualizar dados
              </Button>
              {isAdmin && (
                <Button
                  danger
                  disabled={loading}
                  style={{ marginLeft: 8 }}
                  className="btn-create"
                  type="warning"
                  size="large"
                  onClick={() => setOpenModalAlterarSenha(true)}
                >
                  Alterar senha
                </Button>
              )}
              {isAdmin && !meuPerfil && !usuario.permissões?.alterar_cadastro && (
                <Button
                  danger
                  disabled={loading}
                  style={{ marginLeft: 8 }}
                  className="btn-create"
                  type="danger"
                  size="large"
                  onClick={() => setOpenModalRemover(true)}
                >
                  Remover usuário
                </Button>
              )}
              {isAdmin && (
                <Button
                  disabled={loading}
                  style={{ marginLeft: 8 }}
                  className="btn-create"
                  type="success"
                  icon={<WhatsAppOutlined />}
                  size="large"
                  onClick={sendTestWhatsAppMessage}
                >
                  Testar WhatsApp
                </Button>
              )}
            </Form.Item>
          </Form>
        ) : null}
      </Row>
    </Cards>
  );
};
