import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Input, Radio, Checkbox, Spin, message, Select, DatePicker, Tooltip } from 'antd';
const { Option } = Select;
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import AppContext from '../../context';
import { TopToolBox } from '../lista-de-imoveis/Style';
import { Cliente, Corretor } from 'smart-imob-types';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';
import { DateFromUnsafe } from '../../utility/util_functions';
import ModalDoCliente from './ModalDoCliente';
import { downloadJsonAsExcel as Excel } from '../relatorios/relatorios_pages/RelatoriosUtil';
import { BuscaCodigoOrigem } from './components/BuscaCodigoOrigem';
import FeatherIcon from 'feather-icons-react';
import { TabelaDeClientes } from './components/TabelaDeClientes';
import { ClearOutlined } from '@ant-design/icons';
import { ModalAddClientes } from './components/ModalDeAdicionar';
import { ModalEditClientes } from './components/ModalDeEditar';

const minFields = {
  DDD: undefined,
  CPF: undefined,
  telefone: undefined,
  nome: undefined,
  email: undefined,
  proprietario: false,
  corretor_responsavel: undefined,
};

function downloadObjectAsJson(exportObj: any[], exportName: string) {
  var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('target', '_blank');
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

type BuscaAvancadaInputs = {
  nome: string;
  email: string;
  buscar_excluidos: boolean;
  buscar_proprietarios: boolean;
  telefone: string;
  data_cadastro_min: undefined | Moment | undefined;
  data_cadastro_max: undefined | Moment | undefined;
  bant: undefined | string;
  etapa: number | undefined;
  transacao: undefined | string;
  imovel_origem: undefined | string;
  corretor_responsavel: undefined | string;
  interesse: undefined | string;
  etiquetas: string[];
  formularios: string[];
};

const UserListDataTable = (props: any) => {
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [clientes, setClientes] = useState<any[]>([]);
  const context = useContext(AppContext);
  const [idsSelecionados, setIdsSelecionados] = useState<string[]>([]);
  const [formularios, setFormularios] = useState<any[]>([]);
  const [TableReset, setTableReset] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [clienteModal, setClienteModal] = useState<Cliente | null>(null);
  const [primeiraBusca, setPrimeiraBusca] = useState(true);
  const [buscar, setBuscar] = useState(false);
  const [clientesTotal, setClientesTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ordenacao, setOrdenacao] = useState('recentes');
  const [ImovelObj, setImovelObj] = useState<null | any>(null);
  const [imovel_origem_cod, setimovel_origem_cod] = useState('');
  const [state, setState] = useState<any>({
    selectedRowKeys: 0,
    selectedRows: 0,
    visible: !!props.startAdding,
    editVisible: false,
    update: {},
  });
  const [dataTipo, setDataTipo] = useState<'created_at' | 'edited_at'>('created_at');

  const { update } = state;

  const last_filtro_str = sessionStorage.getItem('last_filtro_clientes');
  const last_filtro = last_filtro_str ? JSON.parse(last_filtro_str) : {};

  if (last_filtro) {
    if (last_filtro.data_cadastro_min) {
      last_filtro.data_cadastro_min = moment(DateFromUnsafe(last_filtro.data_cadastro_min));
    }
    if (last_filtro.data_cadastro_max) {
      last_filtro.data_cadastro_max = moment(DateFromUnsafe(last_filtro.data_cadastro_max));
    }
  }

  const FiltrosIniciais = {
    nome: last_filtro.nome || '',
    buscar_excluidos: last_filtro.buscar_excluidos || false,
    buscar_proprietarios: last_filtro.buscar_proprietarios || false,
    email: last_filtro.email || '',
    telefone: last_filtro.telefone || '',
    data_cadastro_min: last_filtro.data_cadastro_min || null,
    data_cadastro_max: last_filtro.data_cadastro_max || null,
    imovel_origem: last_filtro.imovel_origem || null,
    etiquetas: [],
    bant: last_filtro.bant || null,
    formularios: last_filtro.formularios || [],
    interesse: last_filtro.interesse || null,
    etapa: last_filtro.etapa || null,
    transacao: last_filtro.transacao || null,
    corretor_responsavel: last_filtro.corretor_responsavel || null,
    ...last_filtro,
  };

  const limparFiltros = () => {
    setFiltros(FiltrosIniciais);
    setBuscar(true);
    message.success('Filtros limpos!');
  };
  const [filtros, setFiltros] = useState<BuscaAvancadaInputs>(FiltrosIniciais);

  const handleLimpar = () => {
    limparFiltros();
    // setTimeout(() => setNotifyChange(false), 100);
  };

  const [BuscaExtra, setBuscaExtra] = useState(filtros.imovel_origem ? true : false);

  const handleChange = (campo, valor) => {
    setFiltros({
      ...filtros,
      [campo]: valor,
    });
  };

  const corretoresRelate: Record<string, string> = context.users_empresa.reduce((prev: any, curr: any) => {
    prev[curr.db_id] = curr.nome || curr.email || curr.db_id;
    return prev;
  }, {} as Record<string, string>);

  const [form] = Form.useForm();

  const operadoresEspecificos = {
    nome: 'like',
    email: 'like',
    telefone: 'like',
    data_cadastro_min: 'moreThanOrEqual',
    data_cadastro_max: 'lessThanOrEqual',
    imovel_origem: 'equal',
    corretor_responsavel: 'equal',
    buscar_excluidos: 'equal',
    buscar_proprietarios: 'equal',
    etapa: 'equal',
    transacao: 'equal',
    interesse: 'equal',
    bant: 'equal',
    etiquetas: 'in',
    formularios: 'in',
  };

  const camposEspecificos = {
    nome: 'cliente.nome',
    email: 'cliente.email',
    telefone: 'cliente.telefone',
    data_cadastro_min: `cliente.${dataTipo}`,
    data_cadastro_max: `cliente.${dataTipo}`,
    imovel_origem: 'cliente.imovel_origem',
    corretor_responsavel: 'cliente.corretor_responsavel',
    buscar_excluidos: 'cliente.excluido',
    buscar_proprietarios: 'cliente.proprietario',
    etapa: 'cliente.funil_etapa_venda',
    transacao: 'cliente.transacao',
    interesse: 'cliente.interesse',
    bant: 'cliente.bant',
    etiquetas: 'cliente.etiquetas',
    formularios: 'cliente.formulario_id',
  };

  const processarFiltros = (filtros: any) => {
    const filtrosProcessados: any = [];
    for (const chave in filtros) {
      const valor = filtros[chave];
      if (Array.isArray(valor) && valor.length === 0) continue;
      if (
        valor === null ||
        valor === '' ||
        (!moment.isMoment(valor) &&
          typeof valor !== 'string' &&
          typeof valor !== 'number' &&
          typeof valor !== 'boolean' &&
          !Array.isArray(valor))
      )
        continue;

      const operator = operadoresEspecificos[chave] || 'equal';
      const valorProcessado = operator === 'like' ? `%${valor}%` : valor;
      const campo = camposEspecificos[chave] || `cliente.${chave}`;

      if (chave === 'data_cadastro_min' || chave === 'data_cadastro_max') {
        if (valor && moment.isMoment(valor)) {
          let valorFormatado;

          if (chave === 'data_cadastro_min') {
            // Define para o primeiro horário do dia
            valorFormatado = valor.startOf('day').toISOString();
          } else if (chave === 'data_cadastro_max') {
            // Define para o último horário do dia
            valorFormatado = valor.endOf('day').toISOString();
          }

          if (valorFormatado) {
            filtrosProcessados.push({
              field: campo,
              value: valorFormatado,
              operator: operator,
            });
          }
        }
      } else {
        if (valorProcessado === undefined || valorProcessado === null) continue;

        filtrosProcessados.push({
          field: campo,
          value: valorProcessado,
          operator: operator,
        });
      }
    }
    return filtrosProcessados;
  };

  const fetchClientes = async () => {
    if (!buscar) return;
    try {
      setLoadingClientes(true);
      const maisFiltros: any = [];

      if (ordenacao === 'meus') {
        maisFiltros.push({ field: 'cliente.corretor_responsavel', value: context.user?.db_id, operator: 'equal' });
      }

      if (!(context.user?.acesso_venda && context.user?.acesso_locacao)) {
        if (context.user?.acesso_venda) {
          maisFiltros.push({ field: 'cliente.interesse_venda', value: 1, operator: 'equal' });
        }

        if (context.user?.acesso_locacao) {
          maisFiltros.push({ field: 'cliente.interesse_venda', value: 1, operator: 'ne' });
        } 
      }

      if (!context.user?.permissões.alterar_cadastro && !context.user?.permissões.lead_ver_todos) {
        maisFiltros.push({ field: 'cliente.corretor_responsavel_id', value: context.user?.db_id, operator: 'equal' });
      }
      const filtrosProcessados = processarFiltros(filtros);
      const isFiltrosVazio = Object.keys(filtrosProcessados).length === 0;
      const filtrosParaRequisicao = isFiltrosVazio ? [...maisFiltros] : [...filtrosProcessados, ...maisFiltros];

      const ordemMapa = {
        recentes: { field: 'cliente.created_at', order: 'DESC' },
        edicao: { field: 'cliente.edited_at', order: 'DESC' },
        meus: { field: 'cliente.corretor_responsavel', order: 'DESC' },
      };
      const ordem = ordemMapa[ordenacao];

      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        limit: pageSize.toString(),
        startAt: ((current - 1) * pageSize).toString(),
        order: JSON.stringify([ordem]),
        filtros: JSON.stringify(filtrosParaRequisicao),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/paginado?${params.toString()}`);

      const data = await response.json();
      setClientes(data.nodes);
      setClientesTotal(data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setPrimeiraBusca(false);
      setPrimeiraBusca(false);
      setLoadingClientes(false);
      setBuscar(false);
    }
  };

  const fetchFormularios = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        order: JSON.stringify([{ field: 'form.name', order: 'ASC' }]),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/meta/formularios?${params.toString()}`);

      const data = await response.json();
      setFormularios(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFormularios();
  }, []);

  useEffect(() => {
    fetchClientes();
  }, [pageSize, current, ordenacao, buscar]);

  const onHandleDelete = async (id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Falha ao excluir o cliente');
      }

      setClientes(
        clientes.map(cliente =>
          cliente.db_id === id ? { ...cliente, excluido: true, ativo: 0, edited_at: new Date() } : cliente,
        ),
      );

      setTableReset(true);
    } catch (error) {}
  };

  const onHandleRevive = async (id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/restore/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Falha ao restaurar o cliente');
      }

      setClientes(
        clientes.map(cliente =>
          cliente.db_id === id ? { ...cliente, excluido: false, ativo: 1, edited_at: new Date() } : cliente,
        ),
      );

      setTableReset(true);
    } catch (error) {
      console.error('Erro ao restaurar cliente:', error);
    }
  };

  const showModal = () => {
    setState({
      ...state,
      editVisible: false,
      visible: true,
    });

    setimovel_origem_cod('');
    setImovelObj(null);

    const fields = Object.entries(minFields).map(([key, value]) => ({ name: key, value }));
    form.setFields(fields);
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
      editVisible: false,
      update: {},
    });
    setImovelObj(null);
    setimovel_origem_cod('');
  };

  const usersTableColumns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Imóvel de origem',
      dataIndex: 'imovel_origem',
      key: 'imovel_origem',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
    },
    {
      title: 'Etapa',
      dataIndex: 'etapa',
      key: 'etapa',
    },
    {
      title: 'Interesses',
      dataIndex: 'interesses',
      key: 'interesses',
    },
    {
      title: 'Datas',
      dataIndex: 'data_registro',
      key: 'data_registro',
    },
    {
      title: 'Corretor Responsável',
      dataIndex: 'corretor_responsavel_nome',
      key: 'corretor_responsavel_nome',
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];

  const rowSelection = {
    onChange: (idsSelecionados: any) => setIdsSelecionados(idsSelecionados),
  };

  const showEditModal = (data: any, focusAg = false) => {
    setState({
      ...state,
      visible: false,
      editVisible: true,
      update: data,
    });

    setimovel_origem_cod('');
    setImovelObj(
      data.imovel_origem
        ? {
            value: data.imovel_origem,
          }
        : null,
    );

    //https://ant.design/components/form/#components-form-demo-dynamic-form-item
    //https://ant.design/components/form/#FieldData
    const fields = Object.entries({ ...minFields, ...data }).map(([key, value]) => ({ name: key, value }));
    form.setFields(fields);
    if (focusAg) {
      //não to nem ai não to nem ai
      setTimeout(() => {
        document.getElementById('f_ag_edit')?.focus();
      }, 500);
    }
    //console.log(fields)
  };

  // @todo: pq n usar sync cliente aq?
  const attClientes = async () => {};

  const adicionarModalCliente = cliente => {
    setClienteModal(cliente);
    setShowModalCliente(true);
  };

  const funil_etapa_venda_nomes = context.empresa ? (context.empresa as any).funil_etapa_venda_nomes || {} : {};

  const pesquisar = () => {
    window.scrollTo(0, 0);
    return setBuscar(true);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      pesquisar();
    }
  };

  const onFinishEdit = (updatedCliente: Cliente) => {
    setClientes(clientes.map(cliente => (cliente.db_id === state.update?.db_id ? { ...updatedCliente } : cliente)));
    setTableReset(true);
    setBuscar(true);
  };

  document.addEventListener('keydown', handleKeyDown);

  return (
    <>
      <PageHeader
        ghost
        title="Lista de clientes"
        buttons={[
          <div key="2" className="page-header-actions">
            <Button size="small" key="4" onClick={attClientes} type="primary">
              <FeatherIcon icon="refresh-cw" size={14} />
              Atualizar lista
            </Button>
            <div className="table-toolbox-actions">
              {/* <Button 
                      //@ts-ignore
                      onClick={() => {resetarFiltros}} size="small" type="primary" transparented>
                        Limpar Filtros
                      </Button> */}
              <Button
                //@ts-ignore
                // onClick={() => downloadJsonAsExcel(Clientes.filter(cliente => IdsSelecionados.includes(cliente.db_id)), 'clientes', context)}
                onClick={() =>
                  Excel(
                    clientes
                      .filter(cliente => idsSelecionados.includes(cliente.db_id))
                      .map(cliente => {
                        const funil_etapa_venda_nomes = context.empresa
                          ? (context.empresa as any).funil_etapa_venda_nomes || {}
                          : {};
                        //@ts-ignore
                        const funil_etapas_extra = context.empresa.funil_etapas_extra?.reduce(
                          (prev: any, curr: any) => {
                            prev[curr.extra_i] = curr.title;
                            return prev;
                          },
                          {},
                        );
                        const funil =
                          funil_etapa_venda_nomes[cliente.funil_etapa_venda || 0] ||
                          (funil_etapas_extra
                            ? funil_etapas_extra[cliente.funil_etapa_venda || 0] || 'Indef.'
                            : 'Indef.');
                        return {
                          'Data Criacão': moment(cliente.created_at).format(),
                          'Data Edição': moment(cliente.edited_at).format(),
                          'Nome Completo': cliente.nome,
                          'E-mail': cliente.email,
                          Telefone: `${cliente.DDD} ${cliente.telefone}`,
                          'Etapa Funil': funil,
                          UTM_Source: cliente.utm_source,
                          UTM_Campaign: cliente.utm_campaign,
                          UTM_Medium: cliente.utm_medium,
                          UTM_Content: cliente.utm_content,
                          UTM_Term: cliente.utm_term,
                          Corretor_responsavel: cliente.corretor_responsavel
                            ? corretoresRelate[cliente.corretor_responsavel]
                            : 'Nenhum definido',
                          BANT: cliente.bant,
                          ETIQUETAS:
                            cliente.etiquetas && cliente.etiquetas.length > 0
                              ? cliente.etiquetas.join(', ')
                              : 'Sem Etiqueta Cadastrada',
                        };
                      }),
                    'clientes',
                    true,
                  )
                }
                disabled={idsSelecionados.length === 0}
                size="small"
                type="primary"
                transparented
              >
                Gerar Excel
              </Button>
              <Button
                onClick={() =>
                  downloadObjectAsJson(
                    clientes.filter(cliente => idsSelecionados.includes(cliente.db_id)),
                    'clientes',
                  )
                }
                disabled={idsSelecionados.length === 0}
                size="small"
                type="primary"
                transparented
              >
                Gerar JSON
              </Button>
            </div>
            {(context.user?.permissões.lead_criar || context.user?.permissões.alterar_cadastro) && (
              <Button
                size="small"
                key="5"
                //@ts-ignore
                onClick={showModal}
                type="primary"
              >
                <FeatherIcon icon="plus" size={14} />
                Cadastrar cliente
              </Button>
            )}
          </div>,
        ]}
      />
      {state.editVisible && (
        <ModalEditClientes
          visible={state.editVisible}
          onCancel={onCancel}
          cliente={update}
          imovel_origem_cod={imovel_origem_cod}
          imovel_padrao={ImovelObj}
          onFinishEdit={onFinishEdit}
        />
      )}
      <ModalAddClientes visible={state.visible} onCancel={onCancel} />
      <ModalDoCliente visible={showModalCliente} onCancel={() => setShowModalCliente(false)} cliente={clienteModal} />
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <TopToolBox>
                <Row gutter={15} className="justify-content-around">
                  <Col lg={6} xs={24}>
                    <div className="table-search-box">
                      <AutoComplete
                        dataSource={[]}
                        placeholder="Busca"
                        width="100%"
                        patterns
                        onClick={pesquisar}
                        defaultValue={filtros.nome}
                        onSearch={valor => {
                          handleChange('nome', valor);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={18} lg={16} xs={24}>
                    <div className="table-toolbox-menu">
                      <span className="toolbox-menu-title"> Ordenar por:</span>
                      <Radio.Group onChange={e => setOrdenacao(e.target.value)} value={ordenacao}>
                        <Radio.Button value="recentes">Recentes</Radio.Button>
                        <Radio.Button value="meus">Meus leads</Radio.Button>
                        <Radio.Button value="edicao">Últ. Edição</Radio.Button>
                      </Radio.Group>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_excluidos}
                        onChange={e => {
                          handleChange('buscar_excluidos', e.target.checked);
                        }}
                      >
                        Buscar excluídos
                      </Checkbox>
                      <Checkbox
                        style={{ color: '#5A5F7D' }}
                        checked={filtros.buscar_proprietarios}
                        onChange={e => {
                          handleChange('buscar_proprietarios', e.target.checked);
                        }}
                      >
                        Buscar proprietários
                      </Checkbox>
                      <span className="toolbox-menu-title"> Filtrar por data de:</span>
                      <Radio.Group onChange={e => setDataTipo(e.target.value)} value={dataTipo}>
                        <Radio.Button value="created_at">Cadastro</Radio.Button>
                        <Radio.Button value="edited_at">Edição</Radio.Button>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <Row gutter={15} style={{ paddingTop: 10 }} className="justify-content-around">
                  <Col lg={6} xs={24}>
                    <Input
                      placeholder={'Email'}
                      value={filtros.email}
                      onChange={e => handleChange('email', e.target.value)}
                    />
                  </Col>
                  <Col lg={6} xs={24}>
                    <Input
                      placeholder={'Telefone'}
                      value={filtros.telefone}
                      onChange={e => {
                        handleChange('telefone', e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={6} xs={24}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      locale={locale}
                      style={{ width: '100%' }}
                      placeholder={`Data de ${dataTipo === 'created_at' ? 'cadastro' : 'edição'}: De`}
                      value={filtros.data_cadastro_min}
                      onChange={(date, dateString) => {
                        handleChange('data_cadastro_min', date);
                      }}
                    />
                  </Col>
                  <Col lg={6} xs={24}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      locale={locale}
                      style={{ width: '100%' }}
                      placeholder={`Data de ${dataTipo === 'created_at' ? 'cadastro' : 'edição'}: Até`}
                      value={filtros.data_cadastro_max}
                      onChange={(date, dateString) => {
                        handleChange('data_cadastro_max', date);
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={15} style={{ paddingTop: 10 }} className="justify-content-around">
                  <Col lg={6} xs={24}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      showSearch={true}
                      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      className="normal-select"
                      onChange={valor => {
                        handleChange('etapa', valor);
                      }}
                      placeholder={'Etapa do funil'}
                      value={filtros.etapa}
                    >
                      <Option value={0}>{funil_etapa_venda_nomes[0] || 'Lead'}</Option>
                      <Option value={1}>{funil_etapa_venda_nomes[1] || 'Atendimento'}</Option>
                      <Option value={2}>{funil_etapa_venda_nomes[2] || 'Agendamento'}</Option>
                      <Option value={3}>{funil_etapa_venda_nomes[3] || 'Visita'}</Option>
                      <Option value={4}>{funil_etapa_venda_nomes[4] || 'Proposta'}</Option>
                      <Option value={5}>{funil_etapa_venda_nomes[5] || 'Venda'}</Option>
                      {context?.empresa?.funil_etapas_extra?.map(obj => {
                        return (
                          <Radio value={obj.id} key={'coluna_' + obj.title}>
                            {funil_etapa_venda_nomes[`coluna_${obj.id}`] || obj.title}
                          </Radio>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      className="normal-select"
                      value={filtros.transacao}
                      onChange={valor => {
                        handleChange('transacao', valor);
                      }}
                      placeholder={'Transação'}
                    >
                      {context.user?.acesso_venda && <Option value={'interesse_venda'}>Interessado em venda</Option>}
                      {context.user?.acesso_locacao && (
                        <>
                          <Option value={'interesse_locacao'}>Interessado em locação anual</Option>
                          <Option value={'interesse_locacao_temporada'}>Interessado em locação temporada</Option>
                        </>
                      )}
                    </Select>
                  </Col>
                  {(context.user?.permissões.alterar_cadastro || context.user?.permissões.lead_ver_todos) && (
                    <Col lg={6} xs={24}>
                      <Select
                        style={{ width: '100%' }}
                        allowClear={true}
                        className="normal-select"
                        value={filtros.corretor_responsavel}
                        onChange={valor => {
                          handleChange('corretor_responsavel', valor);
                        }}
                        placeholder={'Corretor responsável'}
                        showSearch={true}
                        filterOption={(input, option) => {
                          try {
                            return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          } catch (error) {
                            console.log(error);
                            return false;
                          }
                        }}
                      >
                        {context.users_empresa.map((user: Corretor) => (
                          <Option key={user.db_id} value={user.db_id}>
                            {user.nome}
                          </Option>
                        ))}
                        {<Option value={'null'}>Nenhum</Option>}
                      </Select>
                    </Col>
                  )}
                  <Col lg={6} xs={24} style={{ display: 'flex', gap: '8px' }}>
                    <Tooltip title="Limpar filtros">
                      <Button
                        onClick={handleLimpar}
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                        icon={<ClearOutlined />}
                      ></Button>
                    </Tooltip>
                    <Button type="primary" onClick={() => setBuscar(true)} style={{ flex: 2 }}>
                      Buscar
                    </Button>
                    <Button type="default" onClick={() => setBuscaExtra(v => !v)} style={{ flex: 2 }}>
                      {BuscaExtra ? '-' : '+'} Filtros
                    </Button>
                  </Col>
                </Row>
                {BuscaExtra && (
                  <Row gutter={15} style={{ paddingTop: 10 }} className="justify-content-around">
                    <Col lg={6} xs={24}>
                      <BuscaCodigoOrigem
                        value={filtros.imovel_origem}
                        onChange={(valor: string) =>
                          setFiltros({
                            ...filtros,
                            imovel_origem: valor,
                          })
                        }
                      />
                    </Col>
                    <Col lg={6} xs={24}>
                      <Select
                        mode="multiple"
                        value={filtros.etiquetas}
                        onChange={valor => {
                          handleChange('etiquetas', valor);
                        }}
                        style={{ width: '100%' }}
                        placeholder="Etiquetas"
                      >
                        {(context.empresa.etiquetas || []).map(etiqueta => (
                          <Option key={etiqueta} value={etiqueta}>
                            {etiqueta}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Select
                        style={{ width: '100%' }}
                        allowClear={true}
                        className="normal-select"
                        value={filtros.bant}
                        onChange={valor => {
                          handleChange('bant', valor);
                        }}
                        placeholder={'BANT'}
                      >
                        {context.empresa?.bant &&
                          context.empresa?.bant.map(bant => (
                            <Option key={bant} value={bant}>
                              {bant}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Select
                        mode="multiple"
                        allowClear={true}
                        value={filtros.formularios}
                        onChange={valor => {
                          handleChange('formularios', valor);
                        }}
                        style={{ width: '100%' }}
                        placeholder="Formulários"
                      >
                        {formularios &&
                          (formularios || []).map((formulario, index) => (
                            <Option key={formulario.id || index} value={formulario.id}>
                              {formulario.name}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col lg={6} xs={24}></Col>
                  </Row>
                )}
              </TopToolBox>

              {loadingClientes ? (
                <div style={{ height: 300 }} className="spin">
                  <Spin />
                </div>
              ) : primeiraBusca ? (
                <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Heading as="h3">Filtre e liste os clientes clicando em buscar</Heading>
                </Col>
              ) : (
                <TabelaDeClientes
                  context={context}
                  CountLoading={loadingClientes}
                  permissões={context.user?.permissões}
                  funil_etapa_venda_nomes={funil_etapa_venda_nomes}
                  FindProprietarios={filtros.buscar_proprietarios}
                  funil_etapas_extra={
                    context?.empresa?.funil_etapas_extra?.reduce((prev: any, curr: any) => {
                      prev[curr.extra_i] = curr.title;
                      return prev;
                    }, {}) as any
                  }
                  setTableReset={setTableReset}
                  TableReset={TableReset}
                  corretoresRelate={corretoresRelate}
                  rowSelection={rowSelection}
                  SearchText={filtros.nome.length >= 2 ? filtros.nome : ''}
                  Clientes={clientes}
                  onHandleRevive={onHandleRevive}
                  usersTableColumns={usersTableColumns}
                  BuscaAvancada={filtros}
                  showEditModal={showEditModal}
                  onHandleDelete={onHandleDelete}
                  adicionarModalCliente={adicionarModalCliente}
                  current={current}
                  pageSize={pageSize}
                  clientesTotal={clientesTotal}
                  setCurrent={setCurrent}
                  setPageSize={setPageSize}
                  setBuscar={setBuscar}
                />
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default UserListDataTable;
