import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Div, FixBreadcumbPadding } from './header-search-style';
import { Popover } from '../popup/popup';
import AppContext from '../../context';
import SmartCache from '../../indexeddb';
import { Button } from '../buttons/buttons';
import { UserDropDwon } from '../utilities/auth-info/auth-info-style';
import { Key } from 'react-feather';

const HeaderSearch = React.memo(
  () => {
    const { url } = useRouteMatch();
    const [Results, setResults] = useState([]);

    const [CodigoKey, setCodigoKey] = useState([]);
    const [ClienteNomeKey, setClienteNomeKey] = useState([]);
    const context = useContext(AppContext);
    const { user } = context;
    const { permissões } = user;

    useEffect(() => {
      (async () => {
        setCodigoKey(await SmartCache.imoveis.getAllByKeyCursor('codigo'));
        setClienteNomeKey(await SmartCache.clientes.getAllByKeyCursor('nome'));
      })();
    }, []);
    const search = async e => {
      const text = e.target.value;
      if (text) {
        const clientes_found = ClienteNomeKey.filter(({ nome }) =>
          (nome || '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              text
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            ),
        );
        const codigos_found = CodigoKey.filter(codigo_key => {
          const codigo_parsed = (String(codigo_key.codigo) || '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          return codigo_parsed.includes(
            (text || '')
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          );
        });

        setResults([
          ...clientes_found
            .map(cliente => ({
              ...cliente,
              tipo: 'cliente',
            }))
            .slice(0, 5),
          ...codigos_found
            .map(codigo => ({
              ...codigo,
              tipo: 'codigo',
            }))
            .sort((a, b) => ((String(b.codigo) || '').toLowerCase() === text ? 1 : -1))
            .slice(0, 5),
          ...context.users_empresa
            .filter(user =>
              (user.nome || '')
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  text
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, ''),
                ),
            )
            .map(user => ({
              ...user,
              tipo: 'funcionario',
            }))
            .slice(0, 4),
        ]);
      } else {
        setResults([]);
      }
    };

    const content = (
      <div>
        {Results.length ? (
          Results.map(resultado => {
            const { tipo } = resultado;
            switch (tipo) {
              case 'cliente':
                return (
                  <FixBreadcumbPadding>
                    <NavLink to={`/admin/cliente/${resultado.key}/geral`}>
                      <Breadcrumb>
                        <Breadcrumb.Item>Cliente</Breadcrumb.Item>
                        <Breadcrumb.Item>{resultado.nome}</Breadcrumb.Item>
                      </Breadcrumb>
                    </NavLink>
                  </FixBreadcumbPadding>
                );
              case 'codigo':
                return (
                  <FixBreadcumbPadding>
                    <NavLink to={`/admin/imovel/${resultado.key}`}>
                      <Breadcrumb>
                        <Breadcrumb.Item>Código</Breadcrumb.Item>
                        <Breadcrumb.Item>{resultado.codigo}</Breadcrumb.Item>
                      </Breadcrumb>
                    </NavLink>
                  </FixBreadcumbPadding>
                );
              case 'funcionario':
                return (
                  <FixBreadcumbPadding>
                    <NavLink to={`/admin/equipe/${resultado.db_id}/editar`}>
                      <Breadcrumb>
                        <Breadcrumb.Item style={{ paddingLeft: 0 }}>Funcionário</Breadcrumb.Item>
                        <Breadcrumb.Item style={{ paddingLeft: 0 }}>{resultado.nome}</Breadcrumb.Item>
                      </Breadcrumb>
                    </NavLink>
                  </FixBreadcumbPadding>
                );
            }
          })
        ) : (
          <NavLink to="#">Nenhum resultado encontrado!</NavLink>
        )}
      </div>
    );

    const dashboard = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin`}>
                <FeatherIcon icon="globe" /> Dash de Performance do Site
              </Link>
            </li>
            {(permissões.financeiro || permissões.alterar_cadastro) && (
              <li>
                <Link to={`/admin/dash-vendas`}>
                  <FeatherIcon icon="dollar-sign" /> Dash de Vendas
                </Link>
              </li>
            )}
            {permissões.alterar_cadastro && context.empresa.utilizar_temporada && (
              <li>
                <Link to={`/admin/dash-temporada`}>
                  <FeatherIcon icon="map" /> Dash de Temporada
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/dash-corretor`}>
                <FeatherIcon icon="git-pull-request" /> Dash de Corretor
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    const imoveis = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/lista-de-imoveis`}>
                <FeatherIcon icon="home" /> Lista de Imóveis
              </Link>
            </li>
            {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
              (permissões.imovel_criar || permissões.alterar_cadastro) && (
                <li>
                  <Link to={`/admin/incluir-imovel`}>
                    <FeatherIcon icon="plus" /> Incluir Imóvel
                  </Link>
                </li>
              )}
            {(permissões.empreendimentos || permissões.alterar_cadastro) && (
              <li>
                <Link to={`/admin/empreendimentos`}>
                  <FeatherIcon icon="box" /> Lista de Empreendimentos
                </Link>
              </li>
            )}
            {(permissões.empreendimentos || permissões.alterar_cadastro) && context.empresa.utilizar_construtoras && (
              <li>
                <Link to={`/admin/construtoras`}>
                  <FeatherIcon icon="truck" /> Lista de Construtoras
                </Link>
              </li>
            )}
            {(permissões.imovel_editar || permissões.alterar_cadastro) && context.empresa.utilizar_arquitetos && (
              <li>
                <Link to={`/admin/arquitetos`}>
                  <FeatherIcon icon="users" /> Lista de Arquitetos
                </Link>
              </li>
            )}
            {((permissões.alterar_cadastro && context.empresa.utilizar_temporada) ||
              context.user.categoria === 'locacao') && (
              <li>
                <Link to={`/admin/reservas`}>
                  <FeatherIcon icon="map-pin" /> Reservas
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/visitas`}>
                <Key size={16} /> Visitas
              </Link>
            </li>
            {(permissões.alterar_cadastro || context.user.categoria === 'venda') && (
              <li>
                <Link to={`/admin/propostas`}>
                  <FeatherIcon icon="clipboard" /> Propostas
                </Link>
              </li>
            )}
          </ul>
        </div>
      </UserDropDwon>
    );

    const pessoas = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/lista-de-clientes`}>
                <FeatherIcon icon="user-check" /> Listagem de Clientes
              </Link>
            </li>
            {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
              (permissões.lead_criar || permissões.alterar_cadastro) && (
                <li>
                  <Link to={`/admin/incluir-cliente`}>
                    <FeatherIcon icon="user-plus" /> Incluir Cliente
                  </Link>
                </li>
              )}
            {((!context.empresa.pipelines_alternativas && permissões.pipeline_leads && context.user?.acesso_venda) ||
              permissões.alterar_cadastro) && (
              <li>
                <Link to={`/admin/pipeline-de-clientes`}>
                  <FeatherIcon icon="activity" /> Funil de Vendas
                </Link>
              </li>
            )}
          </ul>
        </div>
      </UserDropDwon>
    );

    const equipes = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            {permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/equipes`}>
                  <FeatherIcon icon="target" /> Distribuição de Leads
                </Link>
              </li>
            )}
            {permissões.alterar_cadastro && (
              <li>
                <Link to={`/admin/unidades`}>
                  <FeatherIcon icon="pocket" /> Listagem de Unidades
                </Link>
              </li>
            )}
            <li>
              <Link to={`/admin/equipe`}>
                <FeatherIcon icon="users" /> Listagem de Usuários
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    const marketing = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/campanhas`}>
                <FeatherIcon icon="award" /> Campanhas Meta
              </Link>
            </li>
            <li>
              <Link to={`/admin/portais`}>
                <FeatherIcon icon="share" /> Portais
              </Link>
            </li>
            <li>
              <Link to={`/admin/blogs`}>
                <FeatherIcon icon="book" /> Blogs
              </Link>
            </li>
            <li>
              <Link disabled to={`#`}>
                <FeatherIcon icon="send" /> Landing Pages
              </Link>
            </li>
            <li>
              <Link disabled to={`/email-marketing`}>
                <FeatherIcon icon="mail" /> Email Marketing
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    const configuracoes = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/configuracoes`}>
                <FeatherIcon icon="settings" /> Configurações da Empresa
              </Link>
            </li>
            {/* <li>
              <Link disabled to={`/admin/suporte`}>
                <FeatherIcon icon="help-circle" /> Chamados de Suporte
              </Link>
            </li>
            <li>
              <Link disabled to={`/admin/novidades`}>
                <FeatherIcon icon="git-commit" /> Novidades
              </Link>
            </li>
            <li>
              <Link disabled to={`#`}>
                <FeatherIcon icon="skip-forward" /> Tour guiado
              </Link>
            </li>
            <li>
              <Link disabled to={`/admin/tutoriais`}>
                <FeatherIcon icon="youtube" /> Tutoriais
              </Link>
            </li> */}
          </ul>
        </div>
      </UserDropDwon>
    );

    const utilidades = (
      <UserDropDwon>
        <div className="user-dropdwon" style={{ marginTop: 10 }}>
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`/admin/tarefas`}>
                <FeatherIcon icon="layout" /> Tarefas
              </Link>
            </li>
            <li>
              <Link to={`/admin/central_anexos`}>
                <FeatherIcon icon="folder" /> Anexos
              </Link>
            </li>
            <li>
              <Link to={`/admin/agenda`}>
                <FeatherIcon icon="calendar" /> Agenda
              </Link>
            </li>
            <li>
              <Link to={`/admin/relatorios`}>
                <FeatherIcon icon="pie-chart" /> Relatórios
              </Link>
            </li>
          </ul>
        </div>
      </UserDropDwon>
    );

    // );
    return (
      <>
        <Div className="certain-category-search-wrapper" style={{ width: '100%' }}>
          <Row className="ant-row-middle">
            <Col xl={12} lg={12} xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={dashboard} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Dashboard
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={imoveis} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Imóveis
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={pessoas} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Pessoas
                    </Button>
                  </Popover>
                </Link>
                {permissões.alterar_cadastro && (
                  <Link style={{ marginRight: '10px' }} to={url}>
                    <Popover placement={'bottomLeft'} content={equipes} action="hover">
                      <Button
                        type={'white'}
                        outlined
                        icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                        style={{ border: 'none', flexDirection: 'row-reverse' }}
                      >
                        Equipes
                      </Button>
                    </Popover>
                  </Link>
                )}
                {(permissões.marketing || permissões.alterar_cadastro) && (
                  <Link style={{ marginRight: '10px' }} to={url}>
                    <Popover placement={'bottomLeft'} content={marketing} action="hover">
                      <Button
                        type={'white'}
                        outlined
                        icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                        style={{ border: 'none', flexDirection: 'row-reverse' }}
                      >
                        Marketing
                      </Button>
                    </Popover>
                  </Link>
                )}
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={configuracoes} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Configurações
                    </Button>
                  </Popover>
                </Link>
                <Link style={{ marginRight: '10px' }} to={url}>
                  <Popover placement={'bottomLeft'} content={utilidades} action="hover">
                    <Button
                      type={'white'}
                      outlined
                      icon={<FeatherIcon icon="chevron-down" style={{ marginLeft: '3px' }} size={12} />}
                      style={{ border: 'none', flexDirection: 'row-reverse' }}
                    >
                      Ferramentas
                    </Button>
                  </Popover>
                </Link>
              </div>
            </Col>
            <Col xl={12} lg={12} xs={24}>
              <Popover placement={'bottomLeft'} content={content} title="Resultados" action="focus">
                {/* 
              comentado a pedido do cleber
              <FeatherIcon icon="search" size={16} /> */}
                {/* <Input placeholder="Busca rápida!" onInput={search} /> */}
              </Popover>
            </Col>
          </Row>
        </Div>
      </>
    );
  },
  () => true,
);

export default HeaderSearch;
