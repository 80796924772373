import React, { useState, useContext } from 'react';
import { Form, Input, message, DatePicker, Select } from 'antd';
const { Option } = Select;
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import { DateFromUnsafe } from '../../../utility/util_functions';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

interface RegistrarPropostaModalProps {
  visible: boolean;
  onCancel: () => void;
  db_id: string;
}

const RegistrarPropostaModal = React.memo(({ db_id, visible, onCancel }: RegistrarPropostaModalProps) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState<any>(null);

  const last_filtro_str = sessionStorage.getItem('last_filtro_clientes');
  const last_filtro = last_filtro_str ? JSON.parse(last_filtro_str) : {};
  if (last_filtro) {
    if (last_filtro.data_cadastro_min) {
      last_filtro.data_cadastro_min = moment(DateFromUnsafe(last_filtro.data_cadastro_min));
    }
    if (last_filtro.data_cadastro_max) {
      last_filtro.data_cadastro_max = moment(DateFromUnsafe(last_filtro.data_cadastro_max));
    }
  }
  const [CEtapa, SetCEtapa] = useState(last_filtro.etapa || null);
  const funil_etapa_venda_nomes = context.info_empresa ? context.info_empresa.funil_etapa_venda_nomes || {} : {};

  const handleChangeCliente = setCliente;

  const handleOk = async values => {
    setLoading(true);
    try {
      if (!values.descrição) {
        setLoading(false);
        return message.warning('Por favor, descreva a proposta!');
      }
      if (!values.etapa) {
        setLoading(false);
        return message.warning('Por favor, adicione a nova etapa do cliente!');
      }
      if (!values.validade) {
        setLoading(false);
        return message.warning('Por favor, adicione a data de validade da proposta!');
      }
      const toUpdate = {
        edited_at: new Date(),
        edited_by: context.user?.db_id,
        descrição: values.descrição || 'Sem descrição',
        cliente_id: cliente ? cliente.value : null,
        agenciador_id: context.user?.db_id,
        created_at: new Date(),
        agenciador_nome: context.user?.nome || context.user?.email || 'Sem nome',
        validade: new Date(values.validade),
        empresa_id: context.empresa?.db_id,
        imovel_id: db_id,
      };

      await fetch(`${process.env.REACT_APP_API_URI}/propostas`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      message.success('Proposta registrada!');
      onCancel();
    } catch (error) {
      console.error(error);
      message.error('Erro ao registrar proposta!');
    }
    setLoading(false);
  };

  const [form] = Form.useForm();

  return (
    <Modal type={'primary'} title="Cadastro de proposta" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            {
              //@ts-ignore
              <Form form={form} name="proposta" onFinish={handleOk}>
                <Form.Item initialValue="" label="Cliente">
                  {visible && (
                    <FiltrarClientes
                      style={{ height: '100%' }}
                      cliente_obj={cliente}
                      handleChangeCliente={handleChangeCliente}
                      empresa_id={context.empresa?.db_id}
                    />
                  )}
                </Form.Item>
                <Form.Item name="etapa" label="Etapa Funil">
                  {
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      showSearch={true}
                      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      className="height-fix"
                      onChange={e =>
                        (typeof e === 'number' || e === undefined) && SetCEtapa(e === undefined ? null : e)
                      }
                      placeholder={'Etapa do funil'}
                      value={CEtapa || undefined}
                      size="large"
                    >
                      <Option value={0}>{funil_etapa_venda_nomes[0] || 'Lead'}</Option>
                      <Option value={1}>{funil_etapa_venda_nomes[1] || 'Atendimento'}</Option>
                      <Option value={2}>{funil_etapa_venda_nomes[2] || 'Agendamento'}</Option>
                      <Option value={3}>{funil_etapa_venda_nomes[3] || 'Visita'}</Option>
                      <Option value={4}>{funil_etapa_venda_nomes[4] || 'Proposta'}</Option>
                      <Option value={5}>{funil_etapa_venda_nomes[5] || 'Venda'}</Option>
                      {// @ts-ignore
                      context?.empresa?.funil_etapas_extra?.map(({ id, title }) => {
                        return (
                          <Option key={id} value={id}>
                            {funil_etapa_venda_nomes[id] || title}
                          </Option>
                        );
                      })}
                    </Select>
                  }
                </Form.Item>
                <Form.Item name="descrição" initialValue="" label="Descrição da proposta">
                  <Input placeholder="Descreva os detalhes da proposta" />
                </Form.Item>
                <Form.Item name="validade" initialValue="" label="Data de validade da proposta">
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="Selecione a data de validade"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                {
                  //@ts-ignore
                  <Button
                    disabled={loading}
                    onClick={form.submit}
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Registrar proposta!
                  </Button>
                }
              </Form>
            }
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
});

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarPropostaModal;
